<div class="dasboard-container layout-dashboard" [ngClass]="{'drawer-small': toggleMenu, 'drawer-full': !toggleMenu}">
    <header class="top-header header-section" [ngClass]="{'sticky': stickyHeader}">
        <button mat-button type="button" class="menu-collapse-btn d-lg-none d-inline-flex" (click)="toggleDrawer()"
                [disabled]="mfaMandatory" [ngStyle]="{opacity: mfaMandatory ? '0.5' : 1}">
            <ng-container *ngIf="toggleMenu">
                <i class="fa-regular fa-bars"></i>
            </ng-container>
            <ng-container *ngIf="!toggleMenu">
                <i class="fa-regular fa-xmark"></i>
            </ng-container>
        </button>
        <a href="https://countable.co/" target="_blank" class="branding brand" tabindex="0">
            <img class="full-logo" src="/assets/images/logo/countable-header-logo.svg" alt="Countable"/>
            <img class="small-logo" src="/assets/images/global/brand/brand-small.svg" alt="Countable"/>
        </a>
        <app-side-menu-bottom></app-side-menu-bottom>
    </header>

    <div class="content-body">
        <div class="left-sidebar side-nav" [ngClass]="{'dashboard-sticky': stickyHeader}">
            <app-side-menu (onCloseMenu)="toggleDrawer()"></app-side-menu>
        </div>
        <div class="dashboard-main"
             [ngClass]="{'clients-main' : router.url === '/dashboard/members/clients', 'team-main' : router.url === '/dashboard/members/addteammember', 'client-eng-info-page': router.url === '/dashboard/members/client-engagements', 'dashboard-page': router.url === '/dashboard/members/Dashboard', 'dashboard-sticky': stickyHeader}">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<ng-template #downloadFailedPopup>
    <div class="countable-dialog confirmation-dailog">
        <div mat-dialog-title class="dialog-header d-flex flex-column align-items-center p-0">
      <span class="badge badge-error badge-circle icon-bordered-error text-center">
        <i class="fa-regular fa-circle-info"></i>
      </span>
            <h4 class="title text-center">Download Failed</h4>
            <button color="error" class="close" [mat-dialog-close]="false">
                <i class="fa-regular fa-xmark"></i>
            </button>
        </div>
        <div mat-dialog-content class="content dialog-body-content text-center gap-8 mt-2 mb-32">
            <p>
                The file you are trying to download failed
            </p>
        </div>
        <div mat-dialog-actions class="dialog-footer button-group">
            <button mat-button mat-stroked-button color="dark" class="button-medium"
                    [mat-dialog-close]="false">Cancel
            </button>
            <button mat-button mat-flat-button color="error" class="button-medium"
                    [mat-dialog-close]="true">
                Retry
            </button>
        </div>
    </div>
</ng-template>
