import { AfterViewInit, Component, DestroyRef, inject, OnInit, signal, TemplateRef, ViewChild, ViewEncapsulation, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatMenuTrigger } from '@angular/material/menu';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { RoutePath } from 'countable@helpers';
import { FirmModel, UserModel } from 'countable@model';
import { ReviewService } from 'src/app/services/review.service';
import { AclModel } from '../../../model/acl.model';
import { AclService } from '../../../services/acl.service';
import { AuthService } from '../../../services/auth.service';
import { FirmService } from '../../../services/firm.service';
import { UnsavedChangesDialogComponent } from '../../dialogs/unsavedChangesDialog/unsavedChangesDialog.component';
import { CommonFlexComponent } from '../../remote-shared/common-flex/common-flex.component';
import { SharedService } from '../../shared/shared.service';

@Component({
  selector: 'app-side-menu-bottom',
  templateUrl: './side-menu-bottom.component.html',
  styleUrls: ['./side-menu-bottom.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class SideMenuBottomComponent implements OnInit, AfterViewInit {
  @ViewChild('unsavedChanges') unsavedChanges: TemplateRef<any>;
  @ViewChild(MatMenuTrigger) unreadPushNotificationTrigger: MatMenuTrigger;
  @ViewChild(MatMenuTrigger) menuTriggerForUser: MatMenuTrigger;

  nameAcronym: any;
  engagementName: any;
  yearEnd: any;
  profilePic: any = '';
  notifyCount: number;
  notifyOverdueCount: number;
  notifyReminderCount: number;
  disableNotifications: boolean = false;
  showUnreadNotifications: boolean = false;
  showOverDueNotifications: boolean = false;
  showReminderNotifications: boolean = false;
  isUserJustLoggedIn: boolean = false;
  headerStyle: number;
  saveChangeStatus: boolean = false;
  isNotificationOn: boolean;
  mfaMandatory: boolean = false;
  firm: FirmModel = null;
  user: UserModel;
  acl: AclModel;
  firmShortName = signal<string>('');
  showLuca: WritableSignal<boolean> = signal<boolean>(false);
  private destroyRef = inject(DestroyRef);

  constructor(private router: Router, private sharedService: SharedService, private iconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer, private authService: AuthService, private ReviewService: ReviewService,
              private aclService: AclService, private firmService: FirmService, private dialog: MatDialog) {
    this.iconRegistry.addSvgIcon(
      'hour_glass',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/hour_glass.svg')
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.showLuca.set(true);
    }, 1500);
  }

  ngOnInit() {
    this.aclService.subject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(acl => {
      this.acl = acl;
      this.disableNotifications = acl?.isClient;
    });
    this.authService.subjectNewLogin.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.isUserJustLoggedIn = true;
      this.showUnreadNotifications = true;
      // count
      this.sharedService.notifyCountSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(count => {
        this.showUnreadNotifications = false;
        this.notifyCount = count;
        this.showUnreadNotifications = this.isUserJustLoggedIn && this.notifyCount > 0;
        if (this.showUnreadNotifications) {
          setTimeout(() => {
            this.closePushNotification();
          }, 10000);
        }
      });
      // overdue
      this.sharedService.notifyOverdueCountSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(count => {
        this.showOverDueNotifications = false;
        this.notifyOverdueCount = count;
        this.showOverDueNotifications = this.isUserJustLoggedIn && this.notifyOverdueCount > 0;
        if (this.showOverDueNotifications) {
          setTimeout(() => {
            this.closeOverDuePushNotification();
          }, 10000);
        }
      });
      // reminder count
      this.sharedService.notifyRemindercountSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(count => {
        this.showReminderNotifications = false;
        this.notifyReminderCount = count;
        this.showReminderNotifications = this.isUserJustLoggedIn && this.notifyReminderCount > 0;
        if (this.showReminderNotifications) {
          setTimeout(() => {
            this.closeReminderPushNotification();
          }, 10000);
        }
      });
    });
    this.authService.subjectUser.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(user => {
      this.user = user;
      if (user.fullName) {
        let matches = user.fullName.match(/\b(\w)/g);
        let acronym = matches.join('');
        if (acronym && acronym.length > 2) {
          acronym = acronym.slice(0, 2);
        }
        this.nameAcronym = acronym;
      }
    });
    this.authService.subjectUserDetail.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(detail => {
      if (!detail.toshowtermpolicy || detail.userroleId === 10) {
        this.showUnreadNotifications = false;
      }
      this.profilePic = detail?.profilepicture;
    });
    this.firmService.subject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(firm => {
      if (firm) {
        this.firm = firm;
        if (this.firm) {
          this.firmShortName.set(this.firm.name.substring(0, 3));
        }
      }
    });
    this.sharedService.firmStickyHeader.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(data => {
      this.headerStyle = data;
    });
    // notification disable start
    this.sharedService.notificationdbl.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(res => {
      if (res) {
        this.isNotificationOn = res.isNotificationOn;
      }
    });
    // notification disable end
    this.ReviewService.savechangesbtnstatus.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(response => {
      this.saveChangeStatus = response;
    });
    this.ReviewService.navigateToRoute.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(response => {
      if (response) {
        this.saveChangeStatus = false;
        this.ReviewService.savechangesbtnstatus.next(false);
        this.navigateToRoute(response);
      }
    });
    // notification disable end
    this.sharedService.mandatorymfa.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(response => {
      this.mfaMandatory = response;
    });
    // unread notification
    setTimeout(() => {
      localStorage.removeItem('isUserLoggedIn');
      this.isUserJustLoggedIn = false;
      this.showUnreadNotifications = false;
      this.showReminderNotifications = false;
      this.showReminderNotifications = false;
    }, 10000);
  }

  check() {
    if (this.saveChangeStatus) {
      this.dialog.open(UnsavedChangesDialogComponent, {disableClose: true}).afterClosed().subscribe(closed => {
        if (closed) {
          if (this.router.url.includes(RoutePath.BLANKCHECKLIST_TEMPLATE)) {
            this.ReviewService.validateChecklist.next(true);
          } else {
            this.router.navigate(['/dashboard/members/settings/myaccount']);
            this.sharedService.cancelUnsavedChanges.next(true);
            this.saveChangeStatus = false;
            this.ReviewService.savechangesbtnstatus.next(this.saveChangeStatus);
          }
        }
      });
    }
  }

  closePushNotification() {
    if (this.showUnreadNotifications) {
      this.showUnreadNotifications = false;
      if (!this.showOverDueNotifications) {
        localStorage.removeItem('isUserLoggedIn');
      }
    }
  }

  closeOverDuePushNotification() {
    if (this.showOverDueNotifications) {
      this.showOverDueNotifications = false;
      if (!this.showUnreadNotifications) {
        localStorage.removeItem('isUserLoggedIn');
      }
    }
  }

  closeReminderPushNotification() {
    if (this.showReminderNotifications) {
      this.showReminderNotifications = false;
      localStorage.removeItem('isUserLoggedIn');
    }
  }

  logout() {
    this.authService.logout();
  }

  navigateToRoute(from: 'My Account' | 'Whats New' | 'Settings' | 'Billing' | 'Video Tutorials' | 'Leave a Feedback' | 'Contact Support' | 'logout') {
    switch (from) {
      case 'My Account':
        this.router.navigate(['/dashboard/members/settings/myaccount']);
        break;
      case 'Whats New':
        this.router.navigate(['/dashboard/whats-new/announcement']);
        break;
      case 'Settings':
        this.router.navigate(['/dashboard/members/settings/myaccount']);
        break;
      case 'Billing':
        this.router.navigate(['/dashboard/members/settings/billing']);
        break;
      case 'Video Tutorials':
        this.router.navigate(['/dashboard/help/video-tutorials']);
        break;
      case 'Leave a Feedback':
        this.router.navigate(['/dashboard/help/feedback']);
        break;
      case 'Contact Support':
        this.router.navigate(['/dashboard/help/support']);
        break;
      case 'logout':
        this.logout();
        break;
    }
  }

  // switch multiple firms
  openFirmSwitchDialog(dialogType: string) {
    this.dialog.open(CommonFlexComponent, {
      data: {
        dialogType: dialogType,
        dialogHeader: '',
        data: ''
      },
      disableClose: false,
      id: dialogType
    });
  }
}
