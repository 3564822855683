import { ChangeDetectionStrategy, Component, computed, HostListener, OnDestroy, OnInit, Signal, signal, TemplateRef, ViewChild, WritableSignal } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AiUtility, StorageHelper } from 'countable@helpers';
import { AI_KEYS, AiFirmModel, AiInfoModel, LucaClosedModel, LucaDialogInputModel, LucaEngListModel, pageInfo, UserDetailModel } from 'countable@model';
import { AiService, FirmService } from 'countable@services';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { EngApiService } from '../../../services/eng-api.service';
import { EngService } from '../../../services/eng.service';
import { AiBotWindowComponent } from '../../ai-bot/ai-bot-window/ai-bot-window.component';
import { AiWorkingStepperInfoComponent } from '../../ai-bot/ai-working-stepper-info/ai-working-stepper-info.component';

@Component({
    selector: 'app-luca-launcher',
    templateUrl: './luca-launcher.component.html',
    styleUrl: './luca-launcher.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})


export class LucaLauncherComponent implements OnInit, OnDestroy {

  @ViewChild('activatedAI') activatedAI: TemplateRef<any>;
  @ViewChild('deactivatedAI') deactivatedAI: TemplateRef<any>;
  private readonly unSubscribe$: Subject<void> = new Subject<void>();
  public userDetails: WritableSignal<UserDetailModel> = signal<UserDetailModel>(null);
  private clientInfo: WritableSignal<pageInfo> = signal<pageInfo>(null);
  public saveDialogInfo: WritableSignal<LucaClosedModel> = signal<LucaClosedModel>(null);
  public engList: WritableSignal<LucaEngListModel[]> = signal<LucaEngListModel[]>([]);
  private aiFirmInfo: WritableSignal<AiFirmModel> = signal<AiFirmModel>(null);
  public aiUserInfo: WritableSignal<AiInfoModel> = signal<AiInfoModel>(new AiInfoModel());
  public hasAiAccess: Signal<boolean> = computed(() => {
    return !!(this.aiUserInfo()?.isFirmActivateLuca && this.aiUserInfo()?.isUserActivateLuca) ||
      !!(this.aiFirmInfo()?.id > 0 && this.aiUserInfo()?.isFirmActivateLuca) ||
      (this.aiFirmInfo()?.id > 0 && this.aiUserInfo()?.deactivatedBy > 0 && this.aiUtil?.checkIsDeactivated(this.aiUserInfo()?.deactivatedDate))
  });
  public aiUtil: typeof AiUtility = AiUtility;
  public SH: typeof StorageHelper = StorageHelper;
  public readonly AK: typeof AI_KEYS = AI_KEYS;
  public isAiRestricted: WritableSignal<boolean> = signal<boolean>(false);
  public userName: Signal<string> = computed(() => {
    return this.aiUtil?.provideInitials(this.userDetails()?.fullName);
  });

  @HostListener('window:storage', ['$event'])
  onStorageChange(ev: StorageEvent) {
    if (ev?.key === this.AK?.aiState && ev?.oldValue === 'WINDOW' && ev?.newValue === 'CONVERT_DIALOG') {
      const data = JSON.parse(localStorage.getItem('AI_WINDOW_INFO'));
      this.saveDialogInfo.set(data);
      this.openLucaDialog();
    }
    if (ev?.key === this.AK?.aiState && ev?.oldValue === 'WINDOW' && ev?.newValue === 'CLOSE_DIALOG') {
      this.saveDialogInfo.set(null);
    }
  }

  constructor(private router: Router, private spinner: NgxSpinnerService, private dialog: MatDialog, private aiService: AiService,
              private firmService: FirmService, private engApiService: EngApiService, private engService: EngService,
              private activatedRoute: ActivatedRoute) {
    this.userDetails.set(JSON.parse((localStorage.getItem('userDetails'))));
    this.getAIFirmDetails();
    this.getAiStatus();
  }

  ngOnInit() {
    this.firmService.aiInfo.pipe(

    ).subscribe({
      next: res => {
        if (res) {
          this.aiFirmInfo.set(structuredClone(res));
        } else {
          this.aiUserInfo.set(null);
          this.aiFirmInfo.set(null);
        }
      }
    });

    this.engService.refreshAiEngagements$.pipe().subscribe({
      next: res => {
        if (res === true) {
          this.getAllEngagementsList();
        }
      }
    });

    this.engService.refreshSpecificEng$.pipe().subscribe({
      next: engId => {
        if (engId > 0) {
          this.updateSpecificEngagement();
        }
      }
    });

    this.activatedRoute.queryParamMap.subscribe({
      next: params => {
        if (params.has('setHowItWorks') && params.get('setHowItWorks') === 'true') {
          this.openHowItWorks();
        }
        if (params.has('refreshAi') && params.get('refreshAi') === 'true') {
          this.getAiStatus();
          this.getAIFirmDetails();
        }
      }
    });

  }

  private getAllEngagementsList() {
    this.spinner.show();
    this.engApiService.getAllEngagementsList('All').pipe(
      distinctUntilChanged(),
      takeUntil(this.unSubscribe$)
      // map(res => this.filterEngagementsList(structuredClone(res)))
    ).subscribe(response => {
      if (response) {
        const engList = this.engService.getRefinedAllEngagementsList(structuredClone(response), '');
        if (engList?.firmEngagementsList) {
          const refinedEngList = this.filterEngagementsList(structuredClone(engList?.firmEngagementsList));
          this.engList.set(structuredClone(refinedEngList));
        } else {
          this.engList.set([]);
        }
      }
      this.spinner.hide();
    }).add(() => {
      this.spinner.hide();
    });
  }

  public openLucaDialog() {
    const dialogInfo: LucaDialogInputModel = new LucaDialogInputModel();
    dialogInfo.dialogInfo = this.saveDialogInfo()?.closedType === 'MINIMIZE' ? structuredClone(this.saveDialogInfo()) : null;
    dialogInfo.engList = structuredClone(this.engList());
    dialogInfo.pageInfo = this.catchClientInfo(this.router.url);
    dialogInfo.isMinimizedState = this.saveDialogInfo()?.closedType === 'MINIMIZE';
    dialogInfo.hasNoEngagements = this.engList()?.length === 0;
    dialogInfo.isAiRestricted = this.checkRestrictionStatus();
    const dialogRef: MatDialogRef<AiBotWindowComponent, LucaClosedModel> = this.dialog.open(AiBotWindowComponent, {
      data: structuredClone(dialogInfo),
      disableClose: true,
      width: '1600px',
      hasBackdrop: true,
      panelClass: 'custom-mat-dialog-panel'
    });
    this.SH.setStringItem(this.AK.aiState, 'DIALOG');
    this.SH.setItem(this.AK.aiInfo, null);
    dialogRef?.afterOpened().subscribe({
      next: res => {
        this.saveDialogInfo.set(null);
        this.SH.setStringItem(this.AK.aiState, 'DIALOG');
        this.SH.setItem(this.AK.aiInfo, null);
      }
    });
    dialogRef?.afterClosed()?.subscribe({
      next: res => {
        if (res?.closedType === 'MINIMIZE') {
          this.saveDialogInfo.set(structuredClone(res));
        }
        if (res?.closedType === 'CLOSE') {
          this.saveDialogInfo.set(null);
        }
        if (res?.closedType === 'CONVERTED') {
          this.saveDialogInfo.set(structuredClone(res));
        }
        if (res?.closedType === 'NAVIGATE') {
          this.router.navigate([res?.route]).then();
        }
      }
    });
  }

  openHowItWorks() {
    this.dialog.open(AiWorkingStepperInfoComponent).afterClosed().subscribe({
      next: res => {
        if (res) {
          this.router.navigate([], {relativeTo: this.activatedRoute}).then();
          this.openLucaDialog();
        }
      }
    });
  }

  public catchClientInfo(url: string) {
    const clientObj: pageInfo = new pageInfo();
    if (url.includes('client-engagements')) {
      const clientInfo = JSON.parse(localStorage.getItem('selectedClientDetails'));
      clientObj.clientId = clientInfo?.clientfirmId ?? clientInfo?.clientfirmid;
      clientObj.clientName = clientInfo?.cleintLegalCorporationName;
      clientObj.pageType = 'CLIENT';
    } else if (url.includes('create-engagement/1')) {
      const clientInfo = JSON.parse(localStorage.getItem('selectedClientDetails'));
      clientObj.clientId = clientInfo?.clientfirmid;
      clientObj.clientName = clientInfo?.businessname ? clientInfo?.businessname : clientInfo?.clientName;
      clientObj.pageType = 'CLIENT';
    } else if (url.includes('/create-engagement/2')) {
      const engInfo = JSON.parse(localStorage.getItem('selectedEngagementObj'));
      clientObj.clientId = engInfo?.clientfirmid;
      clientObj.clientName = engInfo?.businessname ? engInfo?.businessname : engInfo?.clientName;
      clientObj.pageType = 'ENGAGEMENT';
      clientObj.engName = engInfo?.engagementName ?? engInfo?.engagementname;
      clientObj.engId = engInfo?.engagementsid ?? 0;
    } else if (url.includes('create-engagement/3')) {
    } else if (url.includes('/dashboard/ntrdashboard/')) {
      const engInfo = JSON.parse(localStorage.getItem('selectedEngagementObj'));
      clientObj.clientId = engInfo?.clientfirmid;
      clientObj.clientName = engInfo?.clientName;
      clientObj.pageType = 'ENGAGEMENT';
      clientObj.engName = engInfo?.engagementName ?? engInfo?.engagementname;
      clientObj.engId = engInfo?.engagementsid ?? 0;
    } else if (url.endsWith('/clients')) {
    } else {
      clientObj.pageType = 'NONE';
    }
    this.clientInfo.set(clientObj);
    return clientObj;
  }

  private filterEngagementsList(engList: any[] = []): LucaEngListModel[] | [] {
    if (engList?.length === 0) {
      return [];
    }
    return engList?.filter(e => e?.statusid === 7 || e?.statusid === 9)?.map(e => <LucaEngListModel>{
      clientId: e?.clientfirmid,
      clientName: e?.businessname ? e?.businessname : e?.clientName,
      engName: e?.engagementname ? e?.engagementname : e?.engagementName,
      engId: e?.engagementsid,
      yearEnd: e?.yearEnd,
      engCreatedDate: e?.dateCreated,
      engStatus: e?.status,
      engStatusId: e?.statusid,
      engType: e?.engagementtype,
      year: this.getYear(e?.yearEnd),
      engTypeId: e?.pengagementtypeid
    });
  }

  private getYear = (dateString: string): string => {
    const date = new Date(dateString);
    return date.getFullYear().toString();
  };

  private getAIFirmDetails() {
    this.aiService.fetchAiFirmSettings().pipe(
      takeUntil(this.unSubscribe$)
    ).subscribe({
      next: res => {
        if (res?.status === 200 && res?.data) {
          this.firmService.aiInfo.next(structuredClone(res?.data));
        }
      }
    });
  }

  private getAiStatus() {
    this.aiService.fetchAiUserStatus().pipe(
      takeUntil(this.unSubscribe$)
    ).subscribe({
      next: res => {
        if (res?.status === 200 && res?.data) {
          this.aiUserInfo.set(res?.data);
          if (this.aiUserInfo()?.isFirmActivateLuca && !this.aiUserInfo()?.isUserActivateLuca) {
            this.openActivateAiDialog();
          }
          if (res?.data?.isDeactivationAccepted !== true && this.aiUtil?.checkIsDeactivated(res?.data?.deactivatedDate)) {
            this.openDeactivateDialogForOtherUsers(res?.data?.deactivatedBy);
          }
          if (this.aiUserInfo()?.isFirmActivateLuca || this.isAiRestricted() ||
            (this.aiUserInfo()?.deactivatedBy > 0 && this.aiUtil?.checkIsDeactivated(res?.data?.deactivatedDate))) {
            this.getAllEngagementsList();
          }
        }
      }
    });
  }

  openActivateAiDialog() {
    if (this.userDetails()?.toshowtermpolicy) {
      return;
    }
    this.dialog.open(this.activatedAI).afterClosed().subscribe({
      next: res => {
        this.updateUserStatus();
        if (res) {
          this.openHowItWorks();
        }
      }
    });
  }

  private updateUserStatus() {
    this.aiService.updateAiUserStatus().subscribe({
      next: res => {
        if (res?.status === 200 && res?.data) {
          const userInfo = this.aiUserInfo();
          userInfo.isUserActivateLuca = true;
          this.aiUserInfo.set(userInfo);
        }
      }
    });
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  private openDeactivateDialogForOtherUsers(deactivatedId: number = 0) {
    if (deactivatedId === this.userDetails()?.useracctid) {
      this.isAiRestricted.set(false);
      return;
    } else {
      this.isAiRestricted.set(true);
    }
    this.dialog.open(this.deactivatedAI).afterClosed().subscribe({
      next: res => {
        if (res) {
          this.acknowledgeDeActivation();
        }
      }
    });
  }

  private updateSpecificEngagement(engId: number = 0) {
    const engList: LucaEngListModel[] = JSON.parse(JSON.stringify(this.engList()));
    const eng = engList?.find(e => e?.engId === engId);
    if (eng?.engId > 0) {
      eng.engStatusId = 7;
    }
    this.engList.set(engList);
  }

  private acknowledgeDeActivation() {
    this.aiService.updateDeactivation().subscribe({
      next: res => {
        if (res?.status === 200 && res?.data) {

        }
      }
    });
  }

  private checkRestrictionStatus = () => {
    return (this.aiFirmInfo()?.id > 0 && this.aiFirmInfo()?.isActivated === false) &&
      (this.aiUserInfo()?.deactivatedBy > 0 && this.aiUtil?.checkIsDeactivated(this.aiUserInfo()?.deactivatedDate));
  };

}
