<mat-list class="user-info-bar d-flex flex-row flex-lg-column gap-8" rol="list" *ngIf="acl">
    <!-- Luca -->
    <mat-list-item role="listitem" class="luca-responsive-icon">
        <div #showLuca></div>
        @defer (on viewport(showLuca)) {
            @if(!acl.isClient){
                <app-luca-launcher></app-luca-launcher>
            }
        }
    </mat-list-item>
    <!-- Switch firms -->
    <ng-container *ngIf="user?.hasMultipleFirm">
        <mat-list-item role="listitem" title="{{firm?.name}}">
            <button class="nav-item" title="{{firm?.name}}" (click)="openFirmSwitchDialog('switchFirm')">
                <span class="nav-icon" matBadgeColor="warn">
                    <i class="fa-regular fa-buildings text-white fs-15"></i>
                </span>
                <p class="text-white">{{ firmShortName() }} <i class="fa-regular fa-angle-right text-white"></i> </p>
            </button>
        </mat-list-item>
    </ng-container>
    <!-- Notification -->
    <mat-list-item role="listitem" class="user-info-item notification-count" *ngIf="firm.onboarded"
        [disabled]="firm?.isDeleted"
        [ngClass]="{'disable-container-list': mfaMandatory || firm.isAccountLockedForBilling}">
        <button class="nav-item" aria-label="Notifications" [ngClass]="{'badge-count': notifyCount > 99, 'badge-count1': notifyCount > 999 }"
            [matMenuTriggerFor]="notifications" #unreadPushNotification="matMenuTrigger">
            <span class="nav-icon" matBadge="{{notifyCount > 9 ? '9+' : notifyCount > 0 ? notifyCount : ''}}"
                matBadgePosition="above after" [matBadgeHidden]="isNotificationOn" matBadgeColor="warn">
                <img src="/assets/images/ui-uplift-icons/global/nav-icons/notification.svg" alt="Icon">
            </span>
            <span class="nav-label">Notifications</span>
            <mat-menu #notifications="matMenu" class="notifications-container" #beforeMenu="matMenu" xPosition="before">
                <div #loadNotifications></div>
                @defer (on viewport(loadNotifications)) {
                    <notifications></notifications>
                }
            </mat-menu>
        </button>
    </mat-list-item>
    <!-- Support -->
    <mat-list-item class="user-info-item" role="listitem" (click)="check()">
        <button class="nav-item" #menuTriggerForUser="matMenuTrigger" *ngIf="!profilePic"
            [matMenuTriggerFor]="!saveChangeStatus ? menu : null" [ngClass]="{'picUpdated':profilePic}">
            <ngx-avatars *ngIf="!profilePic" initialsSize="2" bgColor="#fff" fgColor="#000" size="28"
                [name]="user?.fullName"></ngx-avatars>
            <div *ngIf="profilePic" #menuTriggerForUser="matMenuTrigger"
                [matMenuTriggerFor]="!saveChangeStatus ? menu : null" [ngClass]="{'picUpdated':profilePic}">
                <img [src]="profilePic" alt="profile pic" *ngIf="profilePic" class="headerImage" />
            </div>
            <span class="nav-label">{{user?.fullName}}</span>
        </button>
        <mat-menu class="user-dropdownnn user-menu-main" #menu="matMenu" #beforeMenu="matMenu">
            <ng-container *ngIf="acl?.isClient; else otherUsers">
                <button mat-menu-item [ngClass]="{'disable' : firm?.isAccountLockedForBilling}"
                    routerLink="/dashboard/clients/client-settings">
                    <ngx-avatars size="20" [name]="user.fullName" initialsSize="2"></ngx-avatars>
                    <span class="my-account">My Account</span>
                </button>
                <div class="icon-border">
                    <button mat-menu-item routerLink="/dashboard/clients/client-settings"
                        [disabled]="firm?.isAccountLockedForBilling">
                        <i class="fa-regular fa-gear fs-16"></i>
                        <span>Settings</span>
                    </button>
                    <button mat-menu-item [matMenuTriggerFor]="help">
                        <span class="flexbox-row gap-8">
                            <i class="fa-regular fa-message-question"></i>
                            <span class="button-label">Help</span>
                        </span>
                    </button>
                    <mat-menu #help="matMenu" class="video-menu">
                        <button mat-menu-item routerLink="/dashboard/help/support">Contact Support</button>
                    </mat-menu>
                </div>
            </ng-container>
            <!--Partner staff members menu items-->
            <ng-template #otherUsers>
                <button mat-menu-item (click)="navigateToRoute('My Account')"
                        [ngClass]="{'mandatedisable': mfaMandatory}" [disabled]="firm?.isAccountLockedForBilling"
                        *ngIf="firm?.onboarded && !firm?.isAccountLockedForBilling">
                    <ngx-avatars size="20" [name]="user.fullName" initialsSize="2"></ngx-avatars>
                    <span>My Account</span>
                </button>
                <div class="icon-border" [ngClass]="{'mandatedisable': mfaMandatory}">
                    <button mat-menu-item (click)="navigateToRoute('Whats New')">
                        <span class="flexbox-row gap-8">
                            <i class="fa-regular fa-gift"></i>
                            <span>What's New</span>
                        </span>
                    </button>
                    <button mat-menu-item (click)="navigateToRoute('Settings')"
                            [ngClass]="{'mandatedisable': mfaMandatory}" [disabled]="firm?.isAccountLockedForBilling"
                            *ngIf="firm && firm?.onboarded && !firm?.isAccountLockedForBilling">
                        <i class="fa-regular fa-gear fs-16"></i>
                        <span>Settings</span>
                    </button>
                    <button mat-menu-item (click)="navigateToRoute('Billing')"
                            [ngClass]="{'mandatedisable': mfaMandatory}" [disabled]="firm.isDeleted"
                            *ngIf="firm && acl && firm.onboarded && acl.hasBilling">
                        <span class="flexbox-row gap-8">
                            <i class="fa-regular fa-sack-dollar"></i>
                            <span>Billing</span>
                        </span>
                    </button>
                    <ng-container>
                        <button mat-menu-item [matMenuTriggerFor]="helpMenu">
                            <span class="flexbox-row gap-8">
                                <i class="fa-regular fa-message-question"></i>
                                <span class="button-label">Help</span>
                            </span>
                        </button>
                    </ng-container>
                </div>
            </ng-template>
            <button mat-menu-item (click)="logout()">
                <i class="fa-regular fa-arrow-right-from-bracket"></i>
                <span>Log out</span>
            </button>
            <!-- As per the latest design new menu items has been included-->
        </mat-menu>
        <mat-menu #helpMenu="matMenu" class="video-menuu lowerr subMatMenu">
            <button mat-menu-item (click)="navigateToRoute('Video Tutorials')">Video Tutorials</button>
            <button mat-menu-item (click)="navigateToRoute('Contact Support')">Contact Support</button>
        </mat-menu>
    </mat-list-item>
</mat-list>
<!-- Overdue Notifications -->
<div class="unread-notification" *ngIf="acl?.isClient && showOverDueNotifications">
    <p class="message no-marg">You have <span class="convo">{{notifyOverdueCount}}</span> overdue requests</p>
    <div class="preview-hover-items">
        <button mat-button class="transparent-btn more-btn no-pad vert-icon">
            <i class="fa-regular fa-xmark clear-icon" (click)="closeOverDuePushNotification();"></i>
        </button>
    </div>
</div>
<!-- reminder count  Notifications for client -->
<ng-container *ngIf="acl?.isClient && showReminderNotifications">
    <div class="unread-notification" [ngClass]="{'overdue-reminder-pop': showOverDueNotifications && showReminderNotifications && acl?.isClient,
              'reminder-pop': !showOverDueNotifications && showReminderNotifications && acl?.isClient}">
        <p class="message no-marg">You have <span class="convo">{{notifyReminderCount}}</span> reminder notifications
        </p>
        <div class="preview-hover-items">
            <button mat-button class="transparent-btn more-btn no-pad vert-icon" (click)="closeReminderPushNotification();">
                <i class="fa-regular fa-xmark clear-icon"></i>
            </button>
        </div>
    </div>
</ng-container>
<!-- Unread Notifications -->
<div class="unread-notification" *ngIf="showUnreadNotifications">
    <p class="message no-marg">You have <span class="convo">{{notifyCount}}</span> unread notifications</p>
    <div class="preview-hover-items">
        <button mat-button class="transparent-btn more-btn no-pad vert-icon" (click)="closePushNotification();">
            <i class="fa-regular fa-xmark clear-icon"></i>
        </button>
    </div>
</div>
