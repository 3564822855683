import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DeactivateGuardService } from './can-deactivate.guard';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ImportCsvComponent } from './components/index/import-csv/import-csv-component';
import { AuthGuard } from './services/auth.guard';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./components/index/index.module').then(m => m.IndexModule)
  },
  {
    path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], children: [
      {path: '', redirectTo: 'client', pathMatch: 'full'},
      {
        path: 'importcsv',
        component: ImportCsvComponent,
        canActivate: [AuthGuard],
        canDeactivate: [DeactivateGuardService]
      },
      {
        path: 'clients',
        loadChildren: () => import('./components/client/client.module').then(m => m.ClientModule),
        canActivate: [AuthGuard],
        canDeactivate: [DeactivateGuardService]
      },
      {
        path: 'engagement',
        loadChildren: () => import('./components/Engagements/engagement/engagement.module').then(m => m.EngagementModule),
        canActivate: [AuthGuard],
        canDeactivate: [DeactivateGuardService]
      },
      {
        path: 'firm',
        loadChildren: () => import('./components/firm/firm.module').then(m => m.FirmModule),
        canActivate: [AuthGuard],
        canDeactivate: [DeactivateGuardService]
      },
      {
        path: 'partner',
        loadChildren: () => import('./components/partners/partners.module').then(m => m.PartnersModule),
        canActivate: [AuthGuard],
        canDeactivate: [DeactivateGuardService]
      },
      {
        path: 'ntrdashboard',
        loadChildren: () => import('./components/Engagements/NTR_Dashboard/ntr-dashboard.module').then(m => m.NTRDashboardModule),
        canActivate: [AuthGuard],
        canDeactivate: [DeactivateGuardService]
      },
      {
        path: 'members',
        loadChildren: () => import('./components/members/members.module').then(m => m.MembersModule),
        canActivate: [AuthGuard],
        canDeactivate: [DeactivateGuardService]
      },
      {
        path: 'help',
        loadChildren: () => import('./components/help/help.module').then(m => m.HelpModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'whats-new',
        loadChildren: () => import('./components/whats-new/whats-new.module').then(m => m.WhatsnewModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'tinymce-editor',
        loadChildren: () => import('./components/tinymce-editor/tinymce-editor.module').then(m => m.TinymceEditorModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'timetracking',
        loadChildren: () => import('./components/time-tracking/time-tracking.module').then(m => m.TimeTrackingModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  {path: '**', redirectTo: '/', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules, enableTracing: false})],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule {
}
