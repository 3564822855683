import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'yesNoDialog',
  templateUrl: 'yesNoDialog.component.html',
  styleUrls: ['./yesNoDialog.component.scss'],
  standalone: false
})

export class YesNoDialogComponent implements OnInit {
	constructor(public dialogRef: MatDialogRef<YesNoDialogComponent>) {}

	public confirmMessage:string;
	public dialogboxHeader: string;
	public dialogboxText: string;

	ngOnInit() { }
}
