<nps-survey *ngIf="isModalVisible"></nps-survey>
<router-outlet></router-outlet>
<app-terms-privacy></app-terms-privacy>
<ngx-spinner class="ngx-spinner-container" [fullScreen]="true" zIndex="99999" bdColor="rgba(51,51,51,0.8)" size="medium"
             type="circle">
    <div class="loader-container">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="shadow"></div>
        <div class="shadow"></div>
        <div class="shadow"></div>
    </div>
</ngx-spinner>
<ng-template #exportFailed>
    <div class="countable-dialog confirmation-dailog" (click)="$event.stopPropagation()">
        <div mat-dialog-title class="dialog-header d-flex flex-column align-items-center p-0">
        <span class="badge badge-error badge-circle icon-bordered-error text-center">
            <i class="fa-regular fa-circle-info"></i>
        </span>
            <h4 class="title text-center">Export Failed</h4>
            <button color="error" class="close" [mat-dialog-close]="false">
                <i class="fa-regular fa-xmark"></i>
            </button>
        </div>
        <div mat-dialog-content class="content dialog-body-content text-center gap-8 mt-2 mb-32">
            <p>The file you are trying to export failed</p>
        </div>

        <div mat-dialog-actions class="dialog-footer">
            <button mat-button mat-stroked-button class="button-medium" color="dark"
                    [mat-dialog-close]>Cancel
            </button>
            <button mat-button mat-flat-button class="button-medium" color="error"
                    [mat-dialog-close] (click)="retryExport()">Retry
            </button>
        </div>
    </div>
</ng-template>


