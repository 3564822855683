import { Component, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { BillingModel } from 'src/app/model/billing/billing.model';
import { FirmModel } from 'src/app/model/firm/firm.model';
import { ApiService } from 'src/app/services/api.service';
import { BillingService } from 'src/app/services/billing.service';
import { FirmService } from 'src/app/services/firm.service';
import { ReviewService } from 'src/app/services/review.service';
import { TimeTrackerService } from 'src/app/services/time-tracker.service';
import { AclModel } from '../../../model/acl.model';
import { AclService } from '../../../services/acl.service';

import { generateId } from '../../../services/app-config';
import { AuthService } from '../../../services/auth.service';
// import { WebSocketService } from '../../../services/websocket.service';
import { SharedService } from '../../shared/shared.service';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class NotificationsComponent implements OnInit, OnDestroy {
  isNotifications: boolean = true;
  loggedInUserData: any;
  notificationDetails: any = [];
  result: any = [];
  selectedEngagement = 0;
  engagementDropdownList: any = [];
  greeting: any;
  name: string;
  notifySub: Subscription;
  uuid: number;
  recieveNotifySub: Subscription;
  isNotificationOn: boolean = false;
  unReadCount: number;
  //filter: any[] = [{ 'name': 'Select All', 'value': 0, "checked": true }, { 'name': 'Team', 'value': 1, "checked":
  // true }, { 'name': 'Client', 'value': 2, "checked": true }, { 'name': 'Engagements', 'value': 99999, "checked":
  // true }];
  filter = [{'name': 'View All Read', 'value': 11, 'checked': false}, {
    'name': 'View All Unread',
    'value': 12,
    'checked': false
  }];
  searchText;
  isDisableEngDropdown: boolean = false;
  @ViewChild('noteFilter') noteFilter: MatMenuTrigger;
  @ViewChild('notifyMatMenu') notifyMatMenu: MatMenuTrigger;
  refreshNotifySub: Subscription;
  isReadAllFiltered: boolean = false;
  EngagementYearDetails: any;
  trialBalYear: any;
  acl: AclModel;
  savechangesstatusSub: Subscription;
  savechangesstatus: boolean = false;
  billing: BillingModel;
  firm: FirmModel;
  redirectionSub: Subscription;
  private notifySubject: Subject<string> = new Subject();

  constructor(private authService: AuthService, private aclService: AclService, private spinner: NgxSpinnerService,
              private apiService: ApiService, private sharedService: SharedService, private toastr: ToastrService, private router: Router,
              private trackerService: TimeTrackerService, private ReviewService: ReviewService, private billingService: BillingService,
              private firmService: FirmService) {
    this.firmService.subject.pipe(takeUntilDestroyed()).subscribe(firm => {
      if (firm) {
        this.firm = firm;
      }
    });
    this.billingService.subject.pipe(takeUntilDestroyed()).subscribe(billing => {
      if (billing) {
        this.billing = billing;
      }
    });
    this.authService.subjectUserDetail.pipe(takeUntilDestroyed()).subscribe(detail => {
      this.loggedInUserData = detail;
      this.uuid = detail.useracctid;
    });
    this.aclService.subject.pipe(takeUntilDestroyed()).subscribe(acl => {
      this.acl = acl;
    });
  }

  @HostListener('window:storage', ['$event'])
  onStorageChange(ev: StorageEvent) {
    if (ev.key == 'sendManualNotify') {
      if (localStorage.getItem('sendManualNotify') == 'true') {
        this.sendNotification({'uuid': this.uuid, 'isfirm': 0});
      } else {
        localStorage.removeItem('sendManualNotify');
      }
    }
  }

  ngOnInit() {
    this.getNotificationDetails('');
    this.getNotificationSettings();
    this.getEngDropdown();
    this.refreshNotifySub = this.sharedService.notifySubject.subscribe(res => {
      if (res) {
        this.getNotificationDetails(this.searchText);
      }
    });
    this.recieveNotifySub = this.sharedService.sendNotifySubject.subscribe(res => {
      if (res) {
        this.sendNotification(res);
        this.isNotifications && this.getNotificationDetails('');
      }
    });
    this.notifySubject.pipe(
      // if character length greater then 2
      filter(res => res.length > 2 || res.length < 3)
      // Time in milliseconds between key events
      , debounceTime(1000)
      // If previous query is diffent from current
      , distinctUntilChanged()
    ).subscribe((text: string) => {
      this.getNotificationDetails(text);
    });
    this.savechangesstatusSub = this.ReviewService.savechangesbtnstatus.subscribe(response => {
      this.savechangesstatus = response;
    });
    this.redirectionSub = this.ReviewService.redirection.subscribe(response => {
      if (response) {
        this.savechangesstatus = false;
        this.ReviewService.savechangesbtnstatus.next(false);
        this.redirection(response);
      }
    });
  }

  selectAll(checked) {
    this.filter.forEach(e => {
      if (checked) {
        e.checked = true;
      } else {
        e.checked = false;
      }
    });
    this.isDisableEngDropdown = false;
  }

  unSelect() {
    let allSelected1 = this.filter.filter(e => e.checked && e.value != 0).length;
    let engLength = this.filter.filter(e => e.checked && e.value == 99999).length;
    if (engLength == 1) {
      this.isDisableEngDropdown = false;
    } else {
      this.isDisableEngDropdown = true;
    }
    if (allSelected1 && allSelected1 < 3 || allSelected1 == 0) {
      this.filter[0].checked = false;
    } else {
      this.filter[0].checked = true;
    }
  }

  clearAll() {
    this.filter.forEach(e => e.checked = false);
    this.noteFilter.closeMenu();
  }

  handleSearch(searchTerm: string) {
    this.notifySubject.next(searchTerm);
  }

  //onInit Call
  getNotificationDetails(searchText?: string, isHideSpiner?: boolean) {
    let queryString = searchText ? searchText : '';
    let note = [], obj;
    let isReadAllselected = this.filter.filter(e => e.checked && e.value == 11).length;
    let selected = this.filter.filter(e => e.checked).length;

    if (selected == 1) {
      if (isReadAllselected == 1) {
        this.isReadAllFiltered = true;
      } else {
        this.isReadAllFiltered = false;
      }

      this.filter.forEach(e => {
        if (e.checked) {
          let obj = {
            'noteid': e.value
          };
          note.push(obj);
        }
      });
    } else {
      this.isReadAllFiltered = false;
      obj = {
        'noteid': 0
      };
      note.push(obj);
    }

    const inputData = {
      'loginid': this.loggedInUserData.useracctid,
      'isnew': 0,
      'isfirm': 0,
      'search': queryString,
      'engagementsid': this.selectedEngagement,
      'note': note
    };//Need to remove "isnew": 1
    const inputString = JSON.stringify(inputData);

    let data = {
      'procedureName': 'gettracknotification',
      'inputParameters': inputString,
      'inputEnc': generateId[Math.floor(Math.random() * generateId.length)]
    };
    // isHideSpiner ? '' : this.spinner.show();
    this.apiService.getData(data).subscribe(response => {
      isHideSpiner ? '' : this.spinner.hide();
      if (response) {
        let result = typeof response == 'string' ? JSON.parse(response) : response;
        if (result.length > 0) {
          result.forEach(e => {
            if (e.loginid == this.uuid) {
              this.result = e.notification;
              this.notificationDetails = e.notification ? e.notification : [];
              let notifyCount = e.notecountunread;
              let notifyMsg = e.newnotification;
              let overdueCount = e.overduecount;
              let remindercount = e.remindercount;
              this.sharedService.intialLoginNotifySubject.next(true);
              this.sharedService.notifyCountSubject.next(notifyCount);
              this.sharedService.notifyOverdueCountSubject.next(overdueCount);
              this.sharedService.notifyRemindercountSubject.next(remindercount);
              /* Toaster Message */
              this.sharedService.notifyAlertSubject.next(notifyMsg);
              this.getEngDropdown();
            }
          });
        } else {
          this.result = [];
          this.notificationDetails = [];
          this.sharedService.notifyCountSubject.next(0);
          this.sharedService.notifyOverdueCountSubject.next(0);
          this.sharedService.notifyRemindercountSubject.next(0);
        }
      }
    }, error => {
      isHideSpiner ? '' : this.spinner.hide();
    });
  }

  onTurnOffNotification(value) {
    const inputData = {
      'notify':
        [{
          'notificationid': value.notificationid,
          'useraccountid': value.useraccountid,
          'isnotify': false,
          'usernotificationid': value.usernotificationid
        }]
    };
    const inputString = JSON.stringify(inputData);
    let saveData = {
      'procedureName': 'saveusernotification',
      'inputParameters': inputString
    };

    this.spinner.show();
    this.apiService.saveData(saveData).subscribe(response => {
      this.spinner.hide();
      if (response) {
        this.getNotificationDetails(this.searchText);
      }
    }, error => {
      this.spinner.hide();
    });
  }

  trackByNotificationRead(index: number, notificationDetails: any): string {
    return notificationDetails.isread;
  }

  onNotificationRead(value, isSkip?: boolean) {
    if (!isSkip) {
      if (value.isread === 1) {
        this.getNotificationDetails(this.searchText); // CPT-9138
        return;
      }
    }
    if ((this.billing && this.billing.isOnTrial && !this.billing.isTrialExpired) || (this.billing?.plan && !this.firm?.isAccountLockedForBilling && !(this.billing?.isPaused || this.firm?.isPaused))) {
      let isreadnotification;
      let usertracknotificationid;

      if (value) {
        this.notifyMatMenu.closeMenu();
        if (value.isread === 1) { //read msg to unread
          isreadnotification = 0;
          usertracknotificationid = value.usernotificationid;
        } else if (value.isread === 0) { //unread msg to read
          isreadnotification = 1;
          usertracknotificationid = 0;
        }

        const inputData = {
          'usertrack':
            [{
              'tracknotificationid': value.tracknotificationid,
              'useraccountid': value.useraccountid,
              'isread': isreadnotification,
              'isallcleared': 0,
              'usertracknotificationid': usertracknotificationid
            }]
        };
        const inputString = JSON.stringify(inputData);
        let saveData = {
          'procedureName': 'saveusertracknotification',
          'inputParameters': inputString
        };

        // this.spinner.show(); // CPT-9138 for Unreading the Notifications we dont need spinner
        this.apiService.saveData(saveData).subscribe(response => {
          // this.spinner.hide();
          if (response) {
            this.getNotificationDetails(this.searchText);
          }
        }, error => {
          this.spinner.hide();
        });
      }
    }
  }

  sendNotification(res) {
    let queryString = this.searchText ? this.searchText : '';
    this.selectedEngagement = res.engagementsid ? res.engagementsid : 0;
    //this.checkedType = isFilter;
    let note = [], obj;
    let selected = this.filter.filter(e => e.checked).length;
    if (selected == 1) {
      this.filter.forEach(e => {
        if (e.checked) {
          let obj = {
            'noteid': e.value
          };
          note.push(obj);
        }
      });
    } else {
      obj = {
        'noteid': 0
      };
      note.push(obj);
    }
    const inputData = {
      'loginid': res.uuid,
      'isnew': 1,
      'isfirm': res.isfirm,
      'search': queryString,
      'engagementsid': this.selectedEngagement,
      'note': note
    };//Need to remove "isnew": 1
    let data = {
      'procedureName': 'gettracknotification',
      'inputParameters': inputData,
      'inputEnc': generateId[Math.floor(Math.random() * generateId.length)]
    };
    // this.ws._send(JSON.stringify(data));
    localStorage.removeItem('sendManualNotify');
  }

  receiveNotification(res) {
    let result = [];
    result = JSON.parse(res);
    if (result) {
      result.forEach(e => {
        if (e.loginid == this.uuid) {
          this.result = e.notification;
          this.notificationDetails = e.notification;
          let notifyCount = e.notecountunread;
          let notifyMsg = e.newnotification;
          let overdueCount = e.overduecount;
          let remindercount = e.remindercount;
          this.sharedService.notifyCountSubject.next(notifyCount);
          this.sharedService.notifyOverdueCountSubject.next(overdueCount);
          this.sharedService.notifyRemindercountSubject.next(remindercount);
          //Push Notification
          this.sharedService.notifyAlertSubject.next(notifyMsg);
          this.getEngDropdown();
        }
      });
    } else {
      this.result = [];
      this.notificationDetails = [];
      this.sharedService.notifyCountSubject.next(0);
      this.sharedService.notifyOverdueCountSubject.next(0);
      this.sharedService.notifyRemindercountSubject.next(0);
    }
  }

  notify(event) {
    let isNotificationOn;
    if (event.checked) {
      isNotificationOn = 1;
    } else {
      isNotificationOn = 0;
    }
    this.sharedService.notificationdbl.next({isNotificationOn: isNotificationOn == '0' ? true : false});

    const onOffObj = {'issocketon': isNotificationOn, 'loginid': this.uuid};
    const stringObj = JSON.stringify(onOffObj);
    const saveObj = {
      'inputParameters': stringObj,
      'procedureName': 'saveuserwebsocketstatus'
    };
    this.apiService.saveData(saveObj).subscribe(response => {
      if (response) {
        // if(event.checked){
        //     this.ws._connect();
        // }
        // else{
        //     this.ws._disconnect();
        // }
        this.searchText = '';
        this.selectedEngagement = 0;
        this.isNotificationOn = !this.isNotificationOn;
        this.getNotificationDetails(this.searchText, true);
      }
    }, error => {
    });
  }

  getNotificationSettings() {
    const inputData = {'loginid': this.uuid};
    const inputString = JSON.stringify(inputData);
    let data = {
      'procedureName': 'getuserwebsocket',
      'inputParameters': inputString,
      'inputEnc': generateId[Math.floor(Math.random() * generateId.length)]
    };
    this.apiService.getData(data).subscribe(response => {
      if (response) {
        let result = typeof response == 'string' ? JSON.parse(response) : response;
        if (result[0]) {
          this.isNotificationOn = result[0].issocketon == 0 ? false : true;
        }

        if (this.isNotificationOn) {
          // this.ws._connect();
        }
      }
    }, error => {
    });
  }

  getEngDropdown() {
    const inputData = {'loginid': this.uuid};
    const inputString = JSON.stringify(inputData);
    let data = {
      'procedureName': 'getusernotificationengagement',
      'inputParameters': inputString,
      'inputEnc': generateId[Math.floor(Math.random() * generateId.length)]
    };
    this.apiService.getData(data).subscribe(response => {
      if (response) {
        let result = typeof response == 'string' ? JSON.parse(response) : response;
        this.engagementDropdownList = result;
      }
    }, error => {
    });
  }

  //
  markAllAsRead() {
    let unreadList = [];
    this.notificationDetails.forEach(e => {
      if (e.isread === 0) {
        unreadList.push({
          'tracknotificationid': e.tracknotificationid,
          'useraccountid': this.uuid,
          'isread': 1,
          'isallcleared': 0,
          'usertracknotificationid': 0
        });
      }
    });
    if (unreadList.length > 0) {
      const inputData = {
        'usertrack': unreadList
      };
      const inputString = JSON.stringify(inputData);
      let saveData = {
        'procedureName': 'saveusertracknotification',
        'inputParameters': inputString
      };

      this.spinner.show();
      this.apiService.saveData(saveData).subscribe(response => {
        this.spinner.hide();
        this.toastr.success('All notifications marked as read successfully');
        if (response) {
          this.getNotificationDetails(this.searchText);
        }
      }, error => {
        this.spinner.hide();
      });
    }
  }

  //
  deleteAllRead() {
    let readList = [];
    this.notificationDetails.forEach(e => {
      readList.push({'tracknotificationid': e.tracknotificationid, 'loginid': this.uuid});
    });


    const inputData = {
      'usertrack': readList
    };
    const inputString = JSON.stringify(inputData);
    let saveData = {
      'procedureName': 'deleteallnotification',
      'inputParameters': inputString
    };

    this.spinner.show();
    this.apiService.saveData(saveData).subscribe(response => {
      this.filter.forEach(e => {
        e.checked = false;
      });
      this.spinner.hide();
      this.toastr.success('Deleted notifications successfully');
      if (response) {
        this.getNotificationDetails(this.searchText);
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.error);
    });
  }

  redirection(value) {
    if (this.savechangesstatus === true) {
      this.ReviewService.savechangesDialog.next([{'from': 'redirection'}, {'Data': value}]);
    } else {
      if (this.loggedInUserData.userroleId !== 4) {
        if ((this.billing && this.billing.isOnTrial && !this.billing.isTrialExpired) || (this.billing?.plan && !this.firm?.isAccountLockedForBilling && !(this.billing?.isPaused || this.firm?.isPaused))) {
          if (value.moduleid === 4) {
            if (this.acl?.hasEngViewEdit) {
              this.getInsideEngagementData(value);
            }
          } else if (value.moduleid === 3) {
            this.router.navigate(['/dashboard/engagement/engagement-list']);
          } else if (value.moduleid === 2) {
            this.router.navigate(['/dashboard/members/clients']);
          } else if (value.moduleid === 1) {
            this.router.navigate(['/dashboard/members/teammember']);
          }
        }
      } else if (this.loggedInUserData.userroleId == 4) {
        if ((this.billing && this.billing.isOnTrial && !this.billing.isTrialExpired) || (this.billing?.plan && !this.firm?.isAccountLockedForBilling && !(this.billing?.isPaused || this.firm?.isPaused))) {
          if (value.moduleid === 5) {
            const engList = this.sharedService.clientEngList$.getValue();
            if (engList && engList.length) {
              const selectedEng = engList.filter(item => item.engagementsid == value.engagementsid);
              if (selectedEng && selectedEng.length) {
                localStorage.setItem('engagementID', JSON.stringify(value.engagementsid));
                localStorage.setItem('selectedClientEngagementObj', JSON.stringify(selectedEng[0]));
                this.router.navigate(['/dashboard/clients/engagement-details/documents']);
              } else {
                this.router.navigate(['/dashboard/clients/engagements']);
              }

            } else {
              this.router.navigate(['/dashboard/clients/engagements']);
            }

          } else if (value.moduleid === 6) {
            this.router.navigate(['/dashboard/clients/engagement-details/engagement-letters']);
          }
        }
      }
    }
  }

  getInsideEngagementData(value) {
    const inputData = {'loginid': this.loggedInUserData.useracctid, 'engagementsid': value.engagementsid};
    const inputString = JSON.stringify(inputData);
    let data = {
      'procedureName': 'getengfornotification',
      'inputParameters': inputString
    };
    this.spinner.show();
    this.apiService.getData(data).subscribe(response => {
      let data = JSON.parse(response);
      let obj = data[0];
      //console.log(data);
      this.redirectToTrialBal(obj);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.error);
    });
  }

  redirectToTrialBal(engagement) {
    this.sharedService.engagementId = engagement.engagementsid;
    this.sharedService.engagementDetails = engagement;
    this.sharedService.headerObj.next(engagement);
    localStorage.setItem('incorporationDate', engagement.incorporationdate);
    localStorage.setItem('engagementID', engagement.engagementsid);
    localStorage.setItem('engagementClientID', engagement.clientfirmid);
    localStorage.setItem('selectedEngagementObj', JSON.stringify(engagement));
    localStorage.setItem('selectedEngagementObjCopy', JSON.stringify(engagement));
    this.apiService.getEngagementYearDetails(engagement.engagementsid).subscribe(response => {
      if (response) {
        this.EngagementYearDetails = response;

        if (this.EngagementYearDetails && this.EngagementYearDetails.length > 0) {
          this.trialBalYear = this.EngagementYearDetails[0].acctyear;
          localStorage.setItem('trailBalYear', this.trialBalYear);
        }
      }
    }, error => {
      this.toastr.error(error.error);
    });

    localStorage.setItem('endYear', engagement.yearEnd); //both are same
    setTimeout(() => {
      this.trackerService.timeIconCount().subscribe(count => {
        this.sharedService.updateTimerCount.next(count);
        this.sharedService.isTimeTrackerStart.next('startTimer');
      });
      this.router.navigate(['/dashboard/ntrdashboard/ClientOnboarding/ClientAcceptance']);
    }, 500);
  }

  ngOnDestroy() {
    this.notifySub && this.notifySub.unsubscribe();
    this.recieveNotifySub && this.recieveNotifySub.unsubscribe();
    this.refreshNotifySub && this.refreshNotifySub.unsubscribe();
  }
}
