<div class="notifications" (click)=" !isNotificationOn ? $event.stopPropagation() : null">
  <ng-container *ngIf="isNotifications">
    <div class="notification-header p-3" (click)="$event.stopPropagation()">
      <div class="flexbox-row gap-8">
        <h4>Notifications</h4>
        <mat-slide-toggle class="custom-toggle" [checked]="isNotificationOn" (change)="notify($event)"></mat-slide-toggle>
      </div>

      <button mat-button class="button-text ms-auto" [matMenuTriggerFor]="engagementFilter" #noteFilter="matMenuTrigger" (click)="$event.stopPropagation()" [disabled]="!isNotificationOn">
        <i class="fa-solid fa-bars-filter fs-15"></i>
      </button>
      <mat-menu #engagementFilter="matMenu" #beforeMenu="matMenu" xPosition="before" (click)="$event.stopPropagation()">
        <ng-container *ngFor="let f of filter; let isFirst = first; let isLast = last;">
          <button mat-menu-item (click)="$event.stopPropagation();">
            <mat-checkbox [value]="f.value" [(ngModel)]="f.checked" (change)="getNotificationDetails(searchText)">{{f.name}}</mat-checkbox>
          </button>
        </ng-container>
          <button mat-menu-item (click)="$event.stopPropagation();clearAll();getNotificationDetails(searchText)">
            <i class="fa-regular fa-xmark clear-icon"></i>Clear All
          </button>
      </mat-menu>
    </div>

    <div class="flexbox-row gap-12 px-3 py-1 notify-inner" (click)="$event.stopPropagation()">
      <mat-form-field class="fixed-label label-none icon-form-before w-200 me-auto" floatLabel="always">
        <mat-label>Search</mat-label>
        <input matInput placeholder="Search" maxlength="21" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" autocomplete="off" (keyup)="handleSearch($event.target.value)"
        [disabled]="!isNotificationOn">
        <span matPrefix>
            <i class="fa-regular fa-magnifying-glass" draggable="false"></i>
        </span>
      </mat-form-field>
      <!-- <ng-select placeholder="Select Engagement" [items]="engagementDropdownList" bindLabel="engagementname" bindValue="engagementsid" [selectOnTab]="true" [clearable]="false" class="typo-head-dropdown1 map-head-dropdown type-ent"
                  [(ngModel)]="selectedEngagement" (ngModelChange)="getNotificationDetails(searchText)" [disabled]="!isNotificationOn || isDisableEngDropdown">
      </ng-select>-->
      <button mat-button class="button-text" (click)="markAllAsRead();$event.stopPropagation()" [disabled]="!isNotificationOn || notificationDetails.length == 0">
        <i class="fa-regular fa-check"></i>
        <span>Mark All As Read</span></button>
      <button mat-button class="button-text" (click)="deleteAllRead();$event.stopPropagation()" [disabled]="notificationDetails.length === 0">
        <span class="flexbox-row gap-8">
            <i class="fa-regular fa-trash-can"></i>
            <span class="button-label">Delete All</span>
        </span>
      </button>
    </div>

    <ng-container *ngIf="isNotificationOn">
      <!-- <cdk-virtual-scroll-viewport [itemSize]="50" style="height: 490px;"> -->
      <ul class="cust-items mt-3 border-top" style="max-height: calc(100vh - 276px); overflow-y: auto;">
        <li *ngFor="let notification of notificationDetails; trackBy: trackByNotificationRead"
        (click)="redirection(notification);onNotificationRead(notification)"
        [ngStyle]="{'background-color': notification.isread === 0 ? '#EFF4F6' : '' }">
          <div class="notification-item ">
            <div class="flex-box-col">
              <div class="team-member-short-name">
                <ngx-avatars size="28" [name]="notification.createdby" initialsSize="2" class="avatar-shrt-name"></ngx-avatars>
              </div>
              <div class="notification-content">
                <div class="flex-box-left-right notify">
                  <p class="notify-head no-marg ellipse">{{notification.createdby}}</p>
                  <div class="preview-hover-items flex-box-vc-hr">
                    <!-- <p class="notify-time no-marg">{{notification.createddate}}</p> -->
                    <button mat-button class="kebab-button more-btn no-pad vert-icon" [matMenuTriggerFor]="menu" aria-label="" #notifyMatMenu="matMenuTrigger" (click)="$event.stopPropagation()">
                        <i class="fas fa-ellipsis-vertical"></i>
                    </button>
                    <mat-menu #menu="matMenu" class="more-items-container" #beforeMenu="matMenu" xPosition="before" (click)="$event.stopPropagation()">
                      <!--<button mat-menu-item (click)="onTurnOffNotification(notification); $event.stopPropagation()"><span>Turn Off Notification</span></button>-->
                      <button mat-menu-item (click)="onNotificationRead(notification,true);$event.stopPropagation()">
                        <span *ngIf="notification.isread == 1">Mark as Unread</span>
                        <span *ngIf="notification.isread == 0">Mark As Read</span>
                      </button>
                      <button *ngIf="loggedInUserData.userroleId !== 4" mat-menu-item routerLink="/dashboard/members/settings/notifications"><span>Manage</span></button>
                    </mat-menu>
                  </div>
                </div>
                <div class="notify-content">
                  <ng-container *ngIf="notification.type == 1">
                    <!--comments, issues, notes-->
                    <span class="blue-color">{{notification.link}}: </span>
                    <span>{{notification.level}} </span>
                    <span class="bold-color"> {{notification.section1}}</span>
                    <span class="bold-color"> {{notification.section2}}</span>
                  </ng-container>
                  <ng-container *ngIf="notification.type == 2">
                    <!--signoff-->
                    <span class="blue-color">{{notification.link}}: </span>
                    <span class="bold-color"> {{notification.section1}}</span>
                      <span> {{notification.level}}</span>
                      <span class="bold-color"> {{notification.section2}}</span>
                  </ng-container>
                  <ng-container *ngIf="notification.type == 3">
                    <!--invite-->
                    <span class="blue-color">{{notification.link}}: </span>
                    <span>Firm </span>
                    <span class="bold-color"> {{notification.section1}}</span>
                    <span> {{notification.level}} </span>
                    <span class="bold-color"> {{notification.section2}}</span>
                  </ng-container>
                  <ng-container *ngIf="notification.type == 4">
                    <!--platform-->
                    <span class="blue-color">{{notification.link}}: </span>
                    <span> {{notification.level}}</span>
                    <span class="bold-color"> {{notification.section1}}</span>
                    <span class="bold-color"> {{notification.section2}}</span>
                  </ng-container>
                  <ng-container *ngIf="notification.type == 5">
                    <span class="bold-color">{{notification.section1}}</span>
                    <span class="blue-color">{{notification.notificationid == 48 || notification.notificationid == 55 ? "&nbsp;":""}}{{notification.link}}{{notification.notificationid == 48 || notification.notificationid == 55 ? "":":"}} </span>
                    <span>{{notification.level}} </span>
                    <span class="bold-color">{{notification.section2}}</span>
                  </ng-container>
                  <ng-container *ngIf="notification.type == 6">
                    <!--Client Notifications-->
                    <span class="blue-color">{{notification.link}}: </span>
                    <span>{{notification.level}} </span>
                    <span class="bold-color"> {{notification.section1}}</span>
                    <span class="bold-color"> {{notification.section2}}</span>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </li>
        <!--<div class="flex-box-left-right sub-section">
          <p class="notify-head no-marg">Katherine Walsh</p>
          <div class="preview-hover-items flex-box-vc-hr">
            <p class="notify-time no-marg">Today, 01:50 PM</p>
            <button mat-button class="transparent-btn more-btn no-pad vert-icon" [matMenuTriggerFor]="menu"
                    aria-label="" #t="matMenuTrigger" (click)="$event.stopPropagation()">
                <i class="fa-regular fa-ellipsis-vertical more-actions"></i>
            </button>
            <mat-menu #menu="matMenu" class="more-items-container" #beforeMenu="matMenu" xPosition="before"
                      (click)="$event.stopPropagation()">
              <button mat-menu-item (click)="onTurnOffNotification(notification); $event.stopPropagation()"><span>Turn Off Notification</span></button>
              <button mat-menu-item><span>Mark As Read</span></button>
            </mat-menu>
          </div>
        </div>-->
      </ul>
      <!-- </cdk-virtual-scroll-viewport> -->
    </ng-container>
    <!-- As per the latest design notifications disabled UI -->
    <ng-container *ngIf="!isNotificationOn">
      <div class="notify-disable flex-box-row flex-box" (click)="$event.stopPropagation()">
        <i class="fa-regular fa-bell-slash notify-icon"></i>
        <div style="text-align: center">
          <h2>Notifications Disabled</h2>
          <h5>You have disabled notifications.</h5>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="notificationDetails.length == 0 && isNotificationOn">
        <div class="no-rquested-files no-issues-files" (click)="$event.stopPropagation()">
          <h6>No Notifications</h6>
        </div>
    </ng-container>
  </ng-container>
</div>
