import { ChangeDetectorRef, Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { RoutePath } from 'countable@helpers';
import { UserDetailModel } from 'countable@model';
import { Observable } from 'rxjs';
import { ReviewService } from 'src/app/services/review.service';
import { AclModel } from '../../../model/acl.model';
import { FirmModel } from '../../../model/firm/firm.model';
import { AclService } from '../../../services/acl.service';
import { AuthService } from '../../../services/auth.service';
import { FirmService } from '../../../services/firm.service';
import { SharedService } from '../../shared/shared.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  standalone: false
})
export class SideMenuComponent implements OnInit {

  @Output() onCloseMenu = new EventEmitter();

  activePage: any = '';
  isTrackerOn: number;
  mfaMandatory: boolean = false;
  firm: FirmModel = null;
  userDetail: UserDetailModel;
  acl: AclModel;
  disableAccess: boolean = false;
  private saveChangeStatus: boolean = false;
  private destroyRef = inject(DestroyRef);

  constructor(private firmService: FirmService, private router: Router, public sharedService: SharedService,
              private sharedServices: SharedService, private ReviewService: ReviewService,
              private authService: AuthService, private aclService: AclService, private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.aclService.subject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(acl => {
      this.acl = acl;
      this.cdr.markForCheck();
    });
    this.authService.subjectUserDetail.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(detail => {
      this.userDetail = detail;
      if (detail?.toshowtermpolicy) {
        this.disableAccess = true;
      }
      this.cdr.markForCheck();
    });
    this.firmService.subject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(firm => {
      this.firm = firm;
      if (!firm || firm.isAccountLockedForBilling) {
        this.disableAccess = true;
      }
    });
    this.isTrackerOn = +localStorage.getItem('istimetrackingon');
    this.sharedServices.updateTrackerOnOff.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(isOnOff => this.isTrackerOn = isOnOff);
    this.sharedServices.refreshPEGPACTSubscription.pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    this.ReviewService.savechangesbtnstatus.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(response => {
      this.saveChangeStatus = response;
    });
    this.sharedServices.mandatorymfa.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(response => {
      this.mfaMandatory = response;
    });
    this.sharedService.disablekebabmenuopt$.subscribe(response => {
      this.disableAccess = response;
    });
    this.checkActiveRoute();
    this.cdr.markForCheck();
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return !this.saveChangeStatus;
  }

  viewPage(page) {
    this.ReviewService.savechangesbtnstatus.next(this.saveChangeStatus);
    const url = window.location.pathname;
    if (page != url) {
      sessionStorage.removeItem('teamTabIndex');
      localStorage.removeItem('selectedTemplateEngObj');
      localStorage.removeItem('selectedEngagementObj');
      localStorage.removeItem('selectedClientDetails');
      localStorage.removeItem('templateEngTemplateLetter');
      localStorage.removeItem('tempSection');
    }
    this.sharedServices.filterEngagements = false;
    this.sharedServices.selectedClientId = 0;
    this.router.navigate([page]);
    if (page == '/dashboard/firm/templates/engagement-templates' && this.router.url != '/dashboard/firm/templates/engagement-templates') {
      setTimeout(() => {
        this.sharedServices.onloadChecklist.next(true);
      }, 0);
    }
    if (window.innerWidth < 991) {
      this.onCloseMenu.emit();
    }
  }

  openHomePage() {
    window.open('https://countable.co/', '_blank');
  }

  private checkActiveRoute(): void {
    this.sharedServices.routeData.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(data => {
      if (data) {
        let currentRoute = data.url;
        if (currentRoute === '/dashboard/client/client-list' || currentRoute === '/dashboard/client/addclient' || currentRoute === '/dashboard/client/editclient') {
          this.activePage = 'client';
        } else if (currentRoute === '/dashboard/engagement/engagement-list' ||
          currentRoute === '/dashboard/engagement/createengagement' ||
          currentRoute === '/dashboard/engagement/create-engagement' ||
          currentRoute === '/dashboard/engagement/create-engagement/1' ||
          currentRoute === '/dashboard/engagement/create-engagement/2' ||
          currentRoute === '/dashboard/ntrdashboard/ClientOnboarding/ClientAcceptance' ||
          currentRoute === '/dashboard/ntrdashboard/ClientOnboarding/Independence' ||
          currentRoute === '/dashboard/ntrdashboard/ClientOnboarding/Knowledge-Of-clients-business' ||
          currentRoute === '/dashboard/ntrdashboard/ClientOnboarding/Planning' ||
          currentRoute === '/dashboard/ntrdashboard/ClientOnboarding/EngagementLetter' ||
          currentRoute === '/dashboard/ntrdashboard/Procedure' ||
          currentRoute === '/dashboard/ntrdashboard/FinancialStatement/CoverPage' ||
          currentRoute === '/dashboard/ntrdashboard/TrialBalance' ||
          currentRoute === RoutePath.MAERIALITY ||
          currentRoute === RoutePath.REVIEW_CHECKLISTS ||
          currentRoute === RoutePath.ENGAGEMENT_LETTER_VIEW
        ) {
          this.activePage = 'engagement';
        } else if (currentRoute === '/dashboard/myaccount') {
          this.activePage = 'myaccount';
        } else if (currentRoute === '/dashboard/partner/partnerclients' ||
          currentRoute === '/dashboard/importcsv?role=partnerclient' ||
          currentRoute === '/dashboard/engagement/create-engagement' ||
          currentRoute === '/dashboard/engagement/create-engagement/1' ||
          currentRoute === '/dashboard/engagement/create-engagement/2'
        ) {
          this.activePage = 'partnerclients';
        } else if (currentRoute === '/dashboard/members/clients' || currentRoute === '/dashboard/firm/addclients' || currentRoute === '/dashboard/firm/editclients' || currentRoute === '/dashboard/importcsv?role=client') {
          this.activePage = 'firmclients';
        } else if (currentRoute === '/dashboard/members/client-engagements') {
          this.activePage = 'firmclients';
        } else if (currentRoute === '/dashboard/members/Dashboard') {
          this.activePage = 'Dashboard';
        } else if (currentRoute === '/dashboard/members/teammember' || currentRoute === '/dashboard/members/addteammember' || currentRoute === '/dashboard/members/uploadteam' || currentRoute === '/dashboard/firm/editpartner' || currentRoute === '/dashboard/firm/assign-client' || currentRoute === '/dashboard/importcsv?role=partner') {
          this.activePage = 'firmpartners';
        } else if (currentRoute === '/dashboard/clients/Dashboard') {
          this.activePage = 'clientDashboard';
        } else if (currentRoute === '/dashboard/clients/engagements' ||
          currentRoute === '/dashboard/clients/engagement-details/engagement-letters' ||
          currentRoute === '/dashboard/clients/engagement-details/documents' ||
          currentRoute === '/dashboard/clients/engagement-details/financial-statement' ||
          currentRoute === '/dashboard/clients/engagement-details/final-report') {
          this.activePage = 'engagements';
        } else if (currentRoute === '/dashboard/clients/documents') {
          this.activePage = 'documents';
        } else if (currentRoute === '/dashboard/clients/client-settings') {
          this.activePage = 'client-settings';
        } else if (currentRoute === '/dashboard/clients/client-integrations') {
          this.activePage = 'client-integrations';
        } else if (currentRoute === '/dashboard/firm/templates' || currentRoute === '/dashboard/firm/templates/engagement-letters'
          || currentRoute === '/dashboard/firm/templates/headers' || currentRoute === '/dashboard/firm/templates/engagement-details'
          || currentRoute === '/dashboard/firm/templates/header-view' || currentRoute === '/dashboard/firm/templates/footers' || currentRoute === '/dashboard/firm/templates/footer-view' || currentRoute === '/dashboard/firm/templates/engagement-templates' || currentRoute === '/dashboard/firm/templates/signature' || currentRoute === '/dashboard/firm/templates/logos') { this.activePage = 'templates'; } else if ((currentRoute === '/dashboard/firm/settings') || (currentRoute === '/dashboard/firm/settings/teamroles') || (currentRoute === '/dashboard/firm/settings/myaccount')) {
          this.activePage = 'settings';
        } else if ((currentRoute === '/dashboard/members/settings/myaccount#firminfo') || (currentRoute === '/dashboard/members/settings/myaccount#profile') || (currentRoute === '/dashboard/members/settings/myaccount') ||
          (currentRoute === '/dashboard/members/settings/notifications') || (currentRoute === '/dashboard/members/settings/teamaccess') || (currentRoute === '/dashboard/members/settings/billing') || (currentRoute === '/dashboard/members/settings/stripe')) {
          this.activePage = 'settings';
        } else if (currentRoute === ('/dashboard/timetracking/time-summary')) {
          this.activePage = 'timetracking';
        } else { }
      }
    });
  }
}
