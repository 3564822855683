export const BlacklistedPaths: string[] = [
  '/login',
  '/signup',
  '/dashboard/engagement/create-engagement/3',
];

export const WhitelistedUrls: string[] = [
  '/dashboard/members/Dashboard',
  '/dashboard/members/teammember',
  '/dashboard/members/clients',
  '/dashboard/engagement/engagement-list',
  '/dashboard/firm/templates/',
  '/dashboard/members/settings/',
  '/dashboard/clients/engagements',

];

export interface ISurveyHeadings {
  Welcome: string;
  SurveyRequestPara1: string;
  SurveyRequestPara2: string;
  TqHeading: string;
  TqParagraph: string;
  SubmitError: string;
  IgnoreError: string;
  SurveyQuestionIDs: number[];

}

export const SurveyHeadings : ISurveyHeadings = {
  Welcome: 'Hi there!',
  SurveyRequestPara1: 'We\'d like to hear about your experience with us. It would mean the world to us if you could answer only one question so we can make the platform even better for you. Thank you!',
  SurveyRequestPara2: 'How much would you recommend our platform to your friends and family?',
  TqHeading: 'Thank you for your feedback!',
  TqParagraph: 'Our Team will be in touch to chat about how we can keep improving your experience.',
  SurveyQuestionIDs: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  SubmitError : 'Error in Submitting Feedback!',
  IgnoreError : 'Error in Ignoring Feedback!',
};

export interface SurveyFeedback {
  questionId?: number;
  feedbackType: 'Done' | 'Ignore';
  ratingScale?: number;
  userComment?: string;
  ignoreCount: number;
}

export const SURVEY_INTERVALS = {
  INITIAL: 2, // days
  WEEKLY: 7, // days
  MONTHLY: 1, // months
  NEVER: 100 // years
};
