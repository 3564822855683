import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { GenericResModel } from 'countable@model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, distinctUntilChanged, Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { environment } from '../../environments/environment';
import { IExportData } from '../model/export-data.model';

@Injectable({
  providedIn: 'root'
})

export class ExportPlatformService implements OnDestroy {

  public allSubscriptions = new Subscription();
  private readonly unSubscribe$: Subject<void> = new Subject<void>();
  private static readonly URI_FIRM = environment.api + '/platform-export';
  public navigateToHistoryTab$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public exportFailed$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public inProgressCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  exportedEngData: IExportData[] = [];
  public exportHash$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public exportSuccess$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public exportList$: BehaviorSubject<Map<string, 'STARTED' | 'PROGRESS' | 'DONE'>> = new BehaviorSubject<Map<string, 'STARTED' | 'DONE'>>(null);

  constructor(
    private http: HttpClient,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
  ) { }

  public getExportCountData(): Observable<any> {
    return this.http.get<any>(ExportPlatformService.URI_FIRM + '/eng-count');
  }

  public getExportData(type: 'regular' | 'custom', clients?: string): Observable<any> {
    const headers = {};
    if (clients) {
      headers['clients'] = clients;
    }
    return this.http.get<any>(ExportPlatformService.URI_FIRM + '/data?type=' + type, { headers });
  }

  public postExportData(data: any): Observable<any> {
    return this.http.post<GenericResModel<any>>(ExportPlatformService.URI_FIRM + '/data', data);
  }

  public getExportHistoryData(): Observable<any> {
    return this.http.get<any>(ExportPlatformService.URI_FIRM + '/history');
  }

  public getClientsData(): Observable<any> {
    return this.http.get<any>(ExportPlatformService.URI_FIRM + '/client');
  }

  public deleteExportHistory(data: any): Observable<any> {
    return this.http.post<any>(ExportPlatformService.URI_FIRM + '/history', data);
  }

  public downloadFile(data: any): Observable<any> {
    return this.http.post(ExportPlatformService.URI_FIRM + '/download', data, {responseType: 'blob' as 'json'});
  }

  public viewDownloadDetails(data: String): Observable<any> {
    return this.http.post<any>(ExportPlatformService.URI_FIRM + '/download-details', data);
  }

  public exportEngagement(engagementId: number, acctYear: number): Observable<GenericResModel<boolean>> {
    return this.http.post<GenericResModel<boolean>>(ExportPlatformService.URI_FIRM + '/export', {
      'engagementId': engagementId,
      'acctYear': acctYear
    });
  }

  public fetchArchiveStatus(engagementId: number): Observable<GenericResModel<boolean>> {
    return this.http.get<GenericResModel<boolean>>(ExportPlatformService.URI_FIRM + `/archive-status/${engagementId}`);
  }

  public archiveEngagement(engagementId: number, acctYear: number): Observable<GenericResModel<boolean>> {
    return this.http.post<GenericResModel<boolean>>(ExportPlatformService.URI_FIRM + '/archive', {
      'engagementId': engagementId,
      'acctYear': acctYear
    });
  }

  public getCompletionDetails(engId: number): Observable<any> {
    return this.http.get<GenericResModel<any>>(ExportPlatformService.URI_FIRM + '/completion-details',
      {params: {engId: engId}});
  }

  public getCompletionCheckLists(engId: number): Observable<any> {
    return this.http.get<GenericResModel<any>>(ExportPlatformService.URI_FIRM + '/completion-check-lists',
      {params: {engId: engId}});
  }

  public getCompletionAssignPackager(engId: number): Observable<any> {
    return this.http.get<GenericResModel<any>>(environment.api + '/platform-export/assign-packager', {params: {engId: engId}});
  }

  public getCompletionListForFinalReview(engId: number): Observable<any> {
    return this.http.get<GenericResModel<any>>(ExportPlatformService.URI_FIRM + '/completion-list-for-final-review', {params: {engId: engId}});
  }

  public getCompletionDocRequests(engId: number): Observable<any> {
    return this.http.get<GenericResModel<any>>(ExportPlatformService.URI_FIRM + '/completion-doc-requests',
      {params: {engId: engId}});
  }

  public getCompletionEngIssues(engId: number): Observable<any> {
    return this.http.get<GenericResModel<any>>(ExportPlatformService.URI_FIRM + '/completion-eng-issues', {params: {engId: engId}});
  }

  public getCompletionEngComments(engId: number): Observable<any> {
    return this.http.get<GenericResModel<any>>(ExportPlatformService.URI_FIRM + '/completion-eng-comments', {params: {engId: engId}});
  }

  exportData(data: IExportData[]) {
    this.spinner.show();
    this.exportedEngData = JSON.parse(JSON.stringify(data));
    this.allSubscriptions.add(
      this.postExportData(data).pipe(
        takeUntil(this.unSubscribe$),
        distinctUntilChanged(),
      ).subscribe({
        next: (response: any) => {
          if (response?.data && response?.status === 200) {
            this.spinner.hide();
            this.navigateToHistoryTab$.next(true);
          }else{
            this.exportFailed$.next(true);
            this.spinner.hide();
          }
        },
        error: (error: any) => {
          this.exportFailed$.next(true);
          this.spinner.hide();
        }
      })
    );
  }

  retryExportData() {
    this.exportData(this.exportedEngData);
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }
}
