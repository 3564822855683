import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { SurveyUtils } from './helpers/survey-util';
import { ExportPlatformService } from './services/export-platform.service';
import { NPSSurveyService } from './services/survey.service';

declare global {
  interface Window {
    AdobeDC: any;
    google: any;
    grecaptcha: any;
    watsonAssistantChatOptions: any;
    webChatInstance: any;
    webkitSpeechRecognition: any;
    SpeechRecognition: any;
    Plaid: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false
})
export class AppComponent implements OnDestroy {

  @ViewChild('exportFailed') exportFailed: TemplateRef<any>;

  exportFailedSub: Subscription;
  isModalVisible: boolean = false;
  private allSubscriptions: Subscription = new Subscription();

  constructor(public dialog: MatDialog, private exportPlatformService: ExportPlatformService, private npsService: NPSSurveyService) {
    this.npsService.modalVisibility.subscribe(isVisible => {
      const currentUrl = window.location.pathname;
      if (!isVisible) {
        this.isModalVisible = isVisible;
      } else {
        this.isModalVisible = !SurveyUtils.isPathBlacklisted(currentUrl);
      }
    });
    this.exportFailedSub = this.exportPlatformService.exportFailed$.subscribe(res => {
      if (res) {
        this.dialog.open(this.exportFailed);
      }
    });
  }

  retryExport() {
    this.exportPlatformService.retryExportData();
  }

  ngOnDestroy() {
    this.allSubscriptions && this.allSubscriptions.unsubscribe();
    this.exportFailedSub && this.exportFailedSub.unsubscribe();
  }
}

