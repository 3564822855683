import { Component, DestroyRef, inject, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { ExportPlatformService } from '../../services/export-platform.service';
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class DashboardComponent implements OnInit {

  @ViewChild('downloadFailedPopup') downloadFailedPopup: TemplateRef<any>;

  toggleMenu: boolean = true;
  stickyHeader: boolean = false;
  mfaMandatory: boolean = false;
  private allSubscriptions: Subscription = new Subscription();
  private destroyRef = inject(DestroyRef);

  constructor(private sharedServices: SharedService, public router: Router, private exportPlatformService: ExportPlatformService,
              private toaster: ToastrService, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.sharedServices.firmStickyHeader.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(data => {
      this.stickyHeader = data;
    });
    this.sharedServices.mandatorymfa.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(response => {
      this.mfaMandatory = response;
    });
    this.allSubscriptions.add(
      this.exportPlatformService.exportList$.pipe().subscribe({
        next: res => {
          if (res) {
            const exportDetails = Array.from(res.entries()).map(([key, value]) => ({key, value}));
            if (exportDetails && exportDetails?.some(r => r?.value === 'STARTED')) {
              const exportInfo = res;
              exportDetails && exportDetails?.forEach(row => {
                if (row?.value === 'STARTED') {
                  exportInfo.set(row?.key, 'PROGRESS');
                  this.downloadPlatformExportZip(row?.key);
                }
              });
              this.exportPlatformService.exportList$.next(exportInfo);
            }
          }
        }
      })
    );
  }

  toggleDrawer() {
    this.toggleMenu = !this.toggleMenu;
  }

  downloadPlatformExportZip(id: string) {
    this.exportPlatformService.downloadFile(id).pipe(
      distinctUntilChanged(),
      tap(() => this.exportPlatformService.exportSuccess$.next(id))
    ).subscribe({
      next: (response: any) => {
        if (response) {
          saveAs(response, 'Platform Export.zip');
          this.exportPlatformService.exportSuccess$.next(id);
          this.toaster.success('File downloaded successfully');
          const exportInfo = this.exportPlatformService.exportList$.getValue();
          exportInfo.delete(id);
          this.exportPlatformService.exportList$.next(exportInfo);
        } else {
          this.retryDownload(id);
        }
      },
      error: () => {
        this.retryDownload(id);
      }
    });
  }

  private retryDownload(id: string) {
    this.dialog.open(this.downloadFailedPopup).afterClosed().subscribe({
      next: res => {
        if (res) {
          const exportInfo = this.exportPlatformService.exportList$.getValue();
          exportInfo.set(id, 'STARTED');
          this.exportPlatformService.exportList$.next(exportInfo);
        }
      }
    });
  }
}
