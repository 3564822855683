import { ClipboardModule } from '@angular/cdk/clipboard';
import { DATE_PIPE_DEFAULT_OPTIONS, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartModule } from 'angular-highcharts';
import { QRCodeComponent } from 'angularx-qrcode';
import { CookieService } from 'ngx-cookie-service';
import { MarkdownComponent, MarkdownModule, MARKED_OPTIONS, MarkedOptions } from 'ngx-markdown';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DeactivateGuardService } from './can-deactivate.guard';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LucaLauncherComponent } from './components/dashboard/luca-launcher/luca-launcher.component';
import { SideMenuBottomComponent } from './components/dashboard/side-menu-bottom/side-menu-bottom.component';
import { SideMenuComponent } from './components/dashboard/side-menu/side-menu.component';
import { TimeTrackerDashboardHeaderComponent } from './components/dashboard/timetracker-dashboard-header/timetracker-dashboard-header.component';
import { ImportCsvComponent } from './components/index/import-csv/import-csv-component';
import { NotificationsComponent } from './components/index/notifications/notifications.component';
import { NpsSurveyComponent } from './components/nps-survey/nps-survey.component';
import { MessageAlertsComponent } from './components/shared/message-alerts/message-alerts.component';
import { MessagesService } from './components/shared/messages.service';
import { SharedModule } from './components/shared/shared.module';
import { SharedService } from './components/shared/shared.service';
import { CountableTimeZonePipe } from './helpers/countable-timezone.pipe';
import { DnDFirm } from './helpers/dndfirm';
import { AclService } from './services/acl.service';
import { AllAdjustmententryService } from './services/all-adjustmententry.service';
import { ApiCallInterceptor } from './services/api-call-interceptor';
import { AuthGuard } from './services/auth.guard';
import { AuthService } from './services/auth.service';
import { BillingService } from './services/billing.service';
import { AdjustmentExcelService } from './services/excel-adjEntries.service';
import { ExcelService } from './services/excel.service';
import { YesNoDialogComponent } from './components/dialogs/yesNoDialog/yesNoDialog.component';

// @ts-ignore
@NgModule({
  declarations: [
    AppComponent,
    MessageAlertsComponent,
    SideMenuComponent,
    SideMenuBottomComponent,
    DashboardComponent,
    ImportCsvComponent,
    DnDFirm,
    NotificationsComponent,
    TimeTrackerDashboardHeaderComponent,
    NpsSurveyComponent,
    LucaLauncherComponent,
    YesNoDialogComponent
  ],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent], imports: [SharedModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    MatRadioModule,
    MatInputModule,
    MatSidenavModule,
    MatListModule,
    MatTooltipModule,
    MatDialogModule,
    ToastrModule.forRoot({
      preventDuplicates: true
    }),
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useValue: {
          gfm: true,
          breaks: true
        }
      }
    }),
    ChartModule,
    MarkdownComponent,
    ClipboardModule,
    QRCodeComponent,
  ],
  providers: [
    AuthGuard,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiCallInterceptor,
      multi: true
    },
    AclService,
    BillingService,
    SharedService,
    MessagesService,
    CookieService,
    MatDatepickerModule,
    ExcelService, DeactivateGuardService,
    AllAdjustmententryService,
    DatePipe,
    AdjustmentExcelService,
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: {timezone: 'UTC'}},
    CountableTimeZonePipe,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {}
