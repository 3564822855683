@if (hasAiAccess() || isAiRestricted()) {
    <button class="luca-launch-btn text-link"
            [ngClass]="{
            'minimised-state': (saveDialogInfo() && saveDialogInfo()?.closedType === 'MINIMIZE'),
            'disabled': !!(saveDialogInfo()?.disableLauncher)
            }"
            [disabled]="!!(saveDialogInfo()?.disableLauncher)"
            (click)="openLucaDialog()">
        <span class="title d-none">Ask<br>AI</span>
        <span class="icon-block">
            <img src="/assets/images/ui-uplift-icons/luca/bot-new-logo.svg" alt="">
            <span class="dot-16 bg-success-new minimised-icon"></span>
        </span>
    </button>
}


<ng-template #activatedAI>
    <div class="countable-dialog confirmation-dailog" (click)="$event.stopPropagation()" style="width: 400px;">
        <div mat-dialog-title class="dialog-header gap-8 align-items-center">
            <span class="badge badge-success badge-circle icon-bordered-success text-center">
                <i class="fa-regular fa-circle-check text-success"></i>
            </span>
            <h4 class="title">LucaAI Activated Successfully</h4>
            <p class="fs-14 fw-normal text-gray-6">LucaAI has been activated successfully</p>
            <button color="error" class="close" [mat-dialog-close]="false">
                <i class="fa-regular fa-xmark"></i>
            </button>
        </div>


        <div mat-dialog-content class="dialog-body-content">
            <ul class="list-style-none flexbox-column gap-16 card p-3">
                <li class="flexbox-row flex-nowrap gap-8 align-items-start">
                    <i class="fa-regular fa-circle-check text-success"></i>
                    <p class="text-start">Generate prompts for your engagements and automate your workflow with one click.</p>
                </li>
                <li class="flexbox-row flex-nowrap gap-8 align-items-start">
                    <i class="fa-regular fa-circle-check text-success"></i>
                    <p class="text-start">Search, download, and save documents directly to your engagements.</p>
                </li>
                <li class="flexbox-row flex-nowrap gap-8 align-items-start">
                    <i class="fa-regular fa-circle-check text-success"></i>
                    <p class="text-start">Easily expand engagement discovery, simplify workflows, and meet compliance standards.</p>
                </li>
            </ul>
        </div>

        <div mat-dialog-actions class="dialog-footer" (click)="$event.stopPropagation()">
            <!-- <button mat-button mat-stroked-button class="button-medium" color="dark" [mat-dialog-close]="false">
                    Cancel
            </button> -->
            <button mat-button mat-flat-button class="button-medium" color="primary" [mat-dialog-close]="true">
                How It Works
            </button>
        </div>
    </div>
</ng-template>

<ng-template #deactivatedAI>
    <div class="countable-dialog confirmation-dailog deactivatedAI" (click)="$event.stopPropagation()">
        <div mat-dialog-title class="dialog-header gap-8 align-items-center">
            <span class="badge badge-error badge-circle icon-bordered-error text-center">
                <i class="fa-regular fa-circle-info"></i>
            </span>
            <h4 class="title text-center">LucaAI Deactivated</h4>
            <p class="fs-14 fw-normal text-center">The Super Admin has deactivated LucaAI for your firm effective today</p>
            <button color="error" class="close" [mat-dialog-close]="true">
                <i class="fa-regular fa-xmark"></i>
            </button>
        </div>
        <div mat-dialog-content class="content dialog-body-content">
            <p class="text-error fw-semibold">
                Note: Please save all your data from conversation history and complete the work as needed.<br>
                Access will be taken away tonight at 12:00AM.
            </p>
            @if (isAiRestricted()) {
                <div class="flexbox-column gap-12">
                    <p class="text-gray-6">For any queries, please contact your super admin at</p>
                    <div class="flexbox-row gap-8 flex-nowrap justify-content-center">
                        <div class="user-avatar dot dot-34 dot-gray align-self-start">
                            <span>{{ userName() }}</span>
                        </div>
                        <div class="flexbox-column gap-2 align-items-start w-auto">
                            <h6 class="title">{{ aiUserInfo()?.firstName + aiUserInfo()?.lastName }}</h6>
                            <p class="fs-12 text-gray-6">{{ aiUserInfo()?.email }}</p>
                        </div>
                    </div>
                </div>
            }
        </div>

        <div mat-dialog-actions class="dialog-footer" (click)="$event.stopPropagation()">
            <button mat-button mat-flat-button class="button-medium" color="primary"
                    [mat-dialog-close]="true">Ok
            </button>
        </div>
    </div>
</ng-template>
