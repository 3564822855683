<div class="countable-dialog confirmation-dailog yesNo-dialog" (click)="$event.stopPropagation()">
    <div mat-dialog-title class="dialog-header">
        <span class="badge badge-warning badge-circle icon-bordered-warning">
            <i class="fa-regular fa-triangle-exclamation text-warning"></i>
        </span>
        <h4 class="title">{{dialogboxHeader}}</h4>
        <button color="error" class="close" (click)="dialogRef.close(false)">
            <i class="fa-regular fa-xmark"></i>
        </button>
    </div>

    <div mat-dialog-content class="dialog-body-content">
        <p>{{dialogboxText}}</p>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button mat-button mat-stroked-button class="button-medium" color="dark" (click)="dialogRef.close(true)">Cancel</button>
        <button mat-button mat-flat-button class="button-medium" color="primary" (click)="dialogRef.close(false)">Ok</button>
    </div>
</div>
