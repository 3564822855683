<div class="page-wraper">
  <div class="page-title-block bg-transparent">
    <ng-container *ngIf="!uploadSuccess">
      <div class="title-block-left flex-row align-items-center gap-8">
        <button mat-button class="button-text" title="Back" (click)="onBackClick()">
          <i class="fa-regular fa-arrow-left"></i>
        </button>
        <h1 class="titlle h3">Upload Existing {{displayRole}}</h1>
      </div>
    </ng-container>

    <ng-container *ngIf="uploadSuccess">
      <div class="title-block-left flex-row align-items-center gap-8">
        <button mat-button class="button-text" title="Back" (click)="uploadSuccess = false">
          <i class="fa-regular fa-arrow-left"></i>
        </button>
        <h1 class="titlle h3">Upload Existing Clients</h1>
      </div>
    </ng-container>
  </div>


  <div class="content-block-main d-flex flex-column gap-20">
    <div class="d-flex flex-row gap-20 flex-wrap flex-sm-nowrap" *ngIf="!uploadSuccess">
      <a class="text-link d-flex flex-row align-items-center gap-4" target="_blank" (click)="downloadTemplate()">
        <i class="fa-regular fa-arrow-down-to-bracket"></i>
        Download Template
      </a>

      <button class="text-link d-flex flex-row align-items-center gap-4"
        title="How to create template" (click)="editCsv()">
        <i class="fa-regular fa-circle-info"></i>
        How to create template
      </button>
    </div>

    <div class="file-upload" *ngIf="!uploadSuccess">
      <div class="upload-icon" firmDragDrop (onFileDropped)="uploadListener($event, 'dnd')">
        <i class="fa-regular fa-cloud-arrow-up"></i>
      </div>
      <div class="file-wrapper">
        <input type="file" #csvReader name="photo" id="photo" (change)="uploadListener($event, 'manual')"
          accept=".csv" />
        <p class="text-link text-primary fw-medium m-0">Click to upload <span class="text-gray-6 fw-regular">or drag and
            drop</span></p>
      </div>
    </div>
    <span *ngIf="!uploadSuccess">Note<sup class="text-error">*</sup> - For successfully uploading the csv file for
      importing clients, please ensure that none of the text fields have commas. You can use space instead .</span>


    <ng-container *ngIf="uploadSuccess && role=='partner'">
      <div class="importpreview-table">
        <div class="preview-header" *ngIf="processType == 'import'">
          <div class="preview-headerleft">
            <div class="header-text">Import Preview</div>
            <div class="team-members">Total Partners: {{partnersList ? partnersList.length : 0}}</div>
          </div>
          <div class="preview-headerright">
            <button mat-flat-button class="" color="primary" (click)="csvReupload.click()">
              <span class="btn-label">Re-import</span>
            </button>
            <input hidden type="file" #csvReupload name="photo" id="photo" (change)="uploadListener($event, 'manual')"
              accept=".csv" />
          </div>
        </div>
        <div class="upload-table" [ngClass]="{'addnew-section': processType == 'manual'}">
          <div class="tbl-grid-container tbl1-grid-partners">
            <table mat-table [dataSource]="partnersList">
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> ID </th>
                <td mat-cell *matCellDef="let element" class="col-Id" title="{{element.id | uppercase}}"
                  [ngClass]="element.duplicateIDStatus === 1 || element.duplicateIDStatus === 2 || element.isAlphanumericId || element.isIdValidLength? 'text-error' : ' '">
                  <ng-container *ngIf="element.isEditMode; else viewMode">
                    <input #idField maxlength="100" class="field-mat-input" [value]="element.id"
                      [ngClass]="element.duplicateIDStatus || element.isAlphanumericId || element.isIdValidLength ? 'text-error' : ' '"
                      [(ngModel)]="element.id" (input)="checkCharLength($event.target.value)"
                      (keypress)="onlyAlphNumericKeys($event)">
                  </ng-container>
                  <ng-template #viewMode>
                    {{element.id | uppercase}}
                  </ng-template>
                </td>
              </ng-container>
              <ng-container matColumnDef="practiceUnit">
                <th mat-header-cell *matHeaderCellDef> Practice Unit </th>
                <td mat-cell *matCellDef="let element" class="col-prct-unit" title="{{element.practiceUnitName}}">
                  <p> {{element.practiceUnitName}}</p>
                </td>
              </ng-container>

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element" class="col-name"
                  title="{{element.partnerFirstName + ' ' + element.partnerLastName}}">
                  <p> {{element.partnerFirstName + ' ' + element.partnerLastName}} </p>
                </td>
              </ng-container>

              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef class="col-cli-email"> Email </th>
                <td mat-cell *matCellDef="let element" class="col-email" title="{{element.partnerEmail}}"
                  [ngClass]="element.emailValidationStatus ? 'text-error' : ' '">
                  <ng-container *ngIf="element.isEditMode; else viewMode">
                    <input class="field-mat-input" [(ngModel)]="element.partnerEmail" [value]="element.partnerEmail"
                      (input)="onlyLowercase($event, element, 'partner')"
                      [ngClass]="element.emailValidationStatus ? 'text-error' : ' '">
                  </ng-container>
                  <ng-template #viewMode>
                    <p> {{element.partnerEmail}} </p>
                  </ng-template>
                </td>
              </ng-container>

              <ng-container matColumnDef="license">
                <th mat-header-cell *matHeaderCellDef> License # </th>
                <td mat-cell *matCellDef="let element" class="col-lic" title="{{element.licenseNo}}">
                  <p> {{element.licenseNo}}</p>
                </td>
              </ng-container>

              <ng-container matColumnDef="OfficeLocation">
                <th mat-header-cell *matHeaderCellDef> Office Location </th>
                <td mat-cell *matCellDef="let element" class="col-loc" title="{{element.officeLocation}}">
                  <p> {{element.officeLocation}}</p>
                </td>
              </ng-container>

              <ng-container matColumnDef="businessNumber">
                <th mat-header-cell *matHeaderCellDef> Business Number </th>
                <td mat-cell *matCellDef="let element" class="col-busns" title="{{element.partnerBusinessPhone}}">
                  <p> {{element.partnerBusinessPhone}}</p>
                </td>
              </ng-container>

              <ng-container matColumnDef="cell">
                <th mat-header-cell *matHeaderCellDef> Cell Number</th>
                <td mat-cell *matCellDef="let element" class="col-cell" title="{{element.PartnerCellNo}}">
                  <p> {{element.PartnerCellNo}} </p>
                </td>
              </ng-container>

              <ng-container matColumnDef="emailValidationStatus">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element" [ngClass]="(element.emailValidationStatus || element.duplicateIDStatus || element.isIdValidLength) ? 'text-error' : ' '"
                  class="col-stf-call">
                  <ng-container *ngIf="element.emailValidationStatus == 1  && element.duplicateIDStatus == 2">
                    <span class="text-error" title="ID and Email are already Exists">ID and Email are already Exists
                    </span>
                  </ng-container>
                  <ng-container *ngIf="element.emailValidationStatus == 2 && element.duplicateIDStatus == 1">
                    <span class="text-error" title="ID and Email are Duplicate">ID and Email are Duplicate</span>
                  </ng-container>

                  <ng-container *ngIf="element.isIdValidLength">
                    <span class="text-error" title="Max 100 Alphanumeric in ID Column">Max 100 Alphanumeric in ID Column
                    </span>
                  </ng-container>

                  <ng-container *ngIf="(element.duplicateIDStatus && !element.emailValidationStatus) || element.isAlphanumericId">
                    <p>
                      <span class="text-error" title="ID already Exists" *ngIf="element.duplicateIDStatus === 2">ID already Exists</span>
                      <span class="text-error" title="Duplicate ID's in file" *ngIf="element.duplicateIDStatus === 1">Duplicate ID's in file</span>
                      <span class="text-error" title="ID: Alphanumeric" *ngIf="element.isAlphanumericId === 1">ID: Alphanumeric</span>
                    </p>
                  </ng-container>

                  <ng-container *ngIf="!element.duplicateIDStatus && !element.isAlphanumericId && !element.isIdValidLength">
                    <span title="{{ statusCheck[element.emailValidationStatus] }}">
                      {{ statusCheck[element.emailValidationStatus] }}
                    </span>
                  </ng-container>

                </td>
              </ng-container>

              <ng-container matColumnDef="actions" stickyEnd>
                <th mat-header-cell *matHeaderCellDef class="col-action text-end">
                  Actions
                </th>
                <td mat-cell *matCellDef="let element" class="col-action text-end">
                  <div class="button-group justify-content-end flex-nowrap gap-0">
                    <ng-container *ngIf="element.isEditMode; else viewMode">
                      <button mat-button class="button-small-icon" title="done" (click)="onEditSave(element)">
                        <i class="fa-regular fa-check text-success"></i>
                      </button>
                      <button mat-button class="button-small-icon" title="Clear"
                        (click)="element.isEditMode=false; element.partnerEmail = element.oldEmailAddress; element.id = element.oldid">
                        <i class="fa-regular fa-xmark text-error"></i>
                      </button>
                    </ng-container>

                    <ng-template #viewMode>
                      <button mat-button class="button-small-icon" title="Edit" (click)="element.isEditMode=true;
                             element.oldEmailAddress = element.partnerEmail;
                             element.oldid = element.id">
                                      <i class="fa-regular fa-pen fs-15"></i>
                      </button>
                      <button mat-button class="button-small-icon" title="Delete" (click)="onEditDelete(element)">
                        <i class="fa-regular fa-trash-can"></i>
                      </button>
                    </ng-template>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="addPartnersColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: addPartnersColumns;"></tr>
            </table>
          </div>
          <div>
            <button mat-flat-button class="save-btn float-right" color="primary" [disabled]="onValidationClick()"
              [ngClass]="{'disable-button': onValidationClick()}" (click)="saveData()">
              Save
            </button>
          </div>

        </div>

      </div>
    </ng-container>

    <ng-container *ngIf="uploadSuccess && (role=='client' || role=='partnerclient')">
      <div class="page-title-block p-0 mb-3 bg-transparent" *ngIf="processType == 'import'">
        <h5 class="title horizontal-items gap-2 flex-1">
          Import Preview
          <span class="fs-12">Total Clients: {{clientsList ? clientsList.length : 0}}</span>
        </h5>
        <div class="button-group">
          <button mat-button mat-stroked-button color="dark" class="button-medium" title="cancel"
            (click)="uploadSuccess = false">Cancel</button>
          <button mat-button mat-stroked-button color="dark" class="button-medium" (click)="csvReupload.click()">
            <span class="flexbox-row gap-8">
                <i class="fa-regular fa-file-import"></i>
                <span class="button-label">Re-import</span>
            </span>
          </button>
          <input hidden type="file" #csvReupload name="photo" id="photo" (change)="uploadListener($event, 'manual')"
          accept=".csv" />
          <button mat-button mat-flat-button color="primary" class="button-medium" [disabled]="onValidationClick()"
            (click)="saveData()">
            Save
          </button>
        </div>
      </div>
      <div class="card table-responsive" [ngClass]="{'addnew-section': processType == 'manual'}">
        <table mat-table [dataSource]="clientsList" class="customized-sort table-countable cell-p-8">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef style="max-width: 100px;width: 100px;">Client ID</th>
            <td mat-cell *matCellDef="let element" class="col-Id" title="{{element.id | uppercase}}" [ngClass]="element.duplicateIDStatus === 1 || element.duplicateIDStatus === 2 || element.isAlphanumericId || element.isIdValidLength ? 'text-error' : ' '"
              style="max-width: 100px;width: 100px;">
              <ng-container *ngIf="element.isEditMode; else viewMode">
                <mat-form-field floatLabel="always" class="fixed-label label-none form-field-medium">
                  <mat-label>Client ID</mat-label>
                  <input matInput #idField maxlength="100" [value]="element.id"
                    [ngClass]="element.duplicateIDStatus || element.isIdValidLength || element.isAlphanumericId ? 'text-error' : ' '"
                    [(ngModel)]="element.id" (input)="checkCharLength($event.target.value)"
                    (keypress)="onlyAlphNumericKeys($event)">
                </mat-form-field>
              </ng-container>
              <ng-template #viewMode>
                {{element.id | uppercase}}
              </ng-template>
            </td>
          </ng-container>

          <ng-container matColumnDef="legalentityname">
            <th mat-header-cell *matHeaderCellDef style="max-width: 200px;width: 200px;">Entity Name</th>
            <td mat-cell *matCellDef="let element" class="col-cli-legentity" title="{{element.legalEntityName}}"
              [ngClass]="element.duplicateEmailEntityStatus ? 'text-error' : ' '"
              style="max-width: 200px;width: 200px;">
              <ng-container *ngIf="element.isEditMode; else viewMode">
                <mat-form-field floatLabel="always" class="fixed-label label-none form-field-medium">
                  <mat-label>Entity Name</mat-label>
                  <input matInput placeholder="Entity Name" [(ngModel)]="element.legalEntityName"
                    [value]="element.legalEntityName"
                    [ngClass]="element.duplicateEmailEntityStatus ? 'text-error' : ' '">
                </mat-form-field>
              </ng-container>
              <ng-template #viewMode>
                {{element.legalEntityName}}
              </ng-template>
            </td>
          </ng-container>

          <ng-container matColumnDef="typeofentity">
            <th mat-header-cell *matHeaderCellDef>Entity Type</th>
            <td mat-cell *matCellDef="let element" class="col-cli-typofentity" title="{{element.typeOfEntityName}}">
              <ng-container *ngIf="element.isEditMode; else viewMode">
                <mat-form-field floatLabel="always" class="fixed-label label-none form-field-medium">
                  <mat-label>Entity Type</mat-label>
                  <input matInput placeholder="Entity Type" [(ngModel)]="element.typeOfEntityName"
                    [value]="element.typeOfEntityName">
                </mat-form-field>
              </ng-container>
              <ng-template #viewMode>
                {{element.typeOfEntityName}}
              </ng-template>
            </td>
          </ng-container>

          <ng-container matColumnDef="subenttype" *ngIf="loggedInUserData.origin_country_id == 226">
            <th mat-header-cell *matHeaderCellDef>Entity Sub Type</th>
            <td mat-cell *matCellDef="let element" class="col-cli-typofentity" title="{{element.subentityname}}">
              {{element.subentityname}}
            </td>
          </ng-container>

          <ng-container matColumnDef="incorporationdate">
            <th mat-header-cell *matHeaderCellDef>Incorporation Date</th>
            <td mat-cell *matCellDef="let element" title="{{element.incorporationDate | appCountableTimeZone:'MM/dd/yyyy':'UTC'}}">
              {{element.incorporationDate | appCountableTimeZone:'MM/dd/yyyy':'UTC'}}
            </td>
          </ng-container>

          <ng-container matColumnDef="contactpersonname">
            <th mat-header-cell *matHeaderCellDef class="col-cli-cntctper">Contact Name</th>
            <td mat-cell *matCellDef="let element" class="col-cli-cntctper" title="{{element.contactPersonName}}">
              <ng-container *ngIf="element.isEditMode; else viewMode">
                <mat-form-field floatLabel="always" class="fixed-label label-none form-field-medium">
                  <mat-label>Contact Name</mat-label>
                  <input matInput placeholder="Contact Name" [(ngModel)]="element.contactPersonName"
                    [value]="element.contactPersonName">
                </mat-form-field>
              </ng-container>
              <ng-template #viewMode>
                {{element.contactPersonName}}
              </ng-template>
            </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef style="max-width: 180px;width: 180px;">Email</th>
            <td mat-cell *matCellDef="let element" title="{{element.clientEmail}}"
              [ngClass]="element.emailValidationStatus ? 'text-error' : ' '"
              style="max-width: 180px;width: 180px;">
              <ng-container *ngIf="element.isEditMode; else viewMode">
                <mat-form-field floatLabel="always" class="fixed-label label-none form-field-medium">
                  <mat-label>Email</mat-label>
                  <input matInput placeholder="Email" [(ngModel)]="element.clientEmail" [value]="element.clientEmail"
                    [ngClass]="element.emailValidationStatus ? 'text-error' : ' '"
                    (input)="onlyLowercase($event, element, 'client')">
                </mat-form-field>
              </ng-container>
              <ng-template #viewMode>
                {{element.clientEmail}}
              </ng-template>
            </td>
          </ng-container>

          <ng-container matColumnDef="businessPhoneNo">
            <th mat-header-cell *matHeaderCellDef>Business Phone</th>
            <td title="{{element.businessPhone}}" mat-cell *matCellDef="let element">
              {{element.businessPhone}}
            </td>
          </ng-container>

          <ng-container matColumnDef="cellPhoneNo">
            <th mat-header-cell *matHeaderCellDef>Cell Phone</th>
            <td title="{{element.cellPhone}}" mat-cell *matCellDef="let element">
              {{element.cellPhone}}
            </td>
          </ng-container>
          <ng-container matColumnDef="groupName">
            <th mat-header-cell *matHeaderCellDef>Group Name</th>
            <td title="{{element.groupName}}" mat-cell *matCellDef="let element">
              {{element.groupName}}
            </td>
          </ng-container>

          <ng-container matColumnDef="jurisdiction">
            <th mat-header-cell *matHeaderCellDef>Province</th>
            <td title="{{element.jurisdictionName}}" mat-cell *matCellDef="let element">
              {{element.state}}
            </td>
          </ng-container>
          <ng-container matColumnDef="engagementPartnerName">
            <th mat-header-cell *matHeaderCellDef>Engagement Partner</th>
            <td title="{{element.engagementPartnerName}}" mat-cell *matCellDef="let element">
              {{element.engagementPartnerName}}
            </td>
          </ng-container>
          <ng-container matColumnDef="engagementPartnerNameEmail">
            <th mat-header-cell *matHeaderCellDef>Partner Email</th>
            <td title="{{element.engagementPartnerNameEmail}}" mat-cell *matCellDef="let element">
              {{element.engagementPartnerNameEmail}}
            </td>
          </ng-container>


          <ng-container matColumnDef="emailValidationStatus">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element"
              [ngClass]="(element.emailValidationStatus || element.duplicateIDStatus || element.isIdValidLength || element.duplicateEmailEntityStatus || element.InvalidEntityType == 1 || element.InvalidFirstName == 1 || element.InvalidLastName == 1 || element.InvalidEntityName == 1) ? 'text-error' : ' '"
              class="col-stf-call">

              <ng-container *ngIf="element.duplicateIDStatus == 1 && element.duplicateEmailEntityStatus == 1">
                <span title="ID and Entity Name are already Exists">ID and Entity Name are already Exists</span>
              </ng-container>

              <ng-container
                *ngIf="(element.duplicateEmailEntityStatus == 2 && element.duplicateIDStatus == 2) && !element.emailValidationStatus == 1">
                <span title="ID and Email are already Exists">ID and Entity Name are already Exists
                </span>
              </ng-container>

              <ng-container *ngIf="element.duplicateEmailEntityStatus == 1 && !element.duplicateIDStatus == 1">
                <span title="Entity Name are already Exists">Entity Name are already Exists</span>
              </ng-container>

              <ng-container *ngIf="element.emailValidationStatus == 2 && element.duplicateEmailEntityStatus == 1">
                <span title="ID and Email are Duplicate">Entity Name and Email are Duplicate
                </span>
              </ng-container>

              <ng-container
                *ngIf="element.emailValidationStatus == 1 && element.duplicateIDStatus == 1 && element.duplicateEmailEntityStatus == 2">
                <span title="ID and Email are Duplicate">ID, Email and Entity Name are already
                  Exists</span>
              </ng-container>
              <!-- Id already exist and email as well -->
              <ng-container
                *ngIf="(element.emailValidationStatus == 1 && element.duplicateIDStatus == 2) && !element.duplicateEmailEntityStatus">
                <span title="ID and Email are Duplicate">ID and Email are already Exists</span>
              </ng-container>

              <ng-container
                *ngIf="(!element.emailValidationStatus && !element.duplicateIDStatus) && element.duplicateEmailEntityStatus == 2">
                <span title="ID and Email are Duplicate">Entity Name are already Exists</span>
              </ng-container>

              <ng-container *ngIf="element.duplicateIDStatus == 2 && element.duplicateEmailEntityStatus == 1 ">
                <span title="ID and Email are Duplicate">ID and Entity Name are already Exists
                </span>
              </ng-container>

              <ng-container
                *ngIf="(element.duplicateEmailEntityStatus == 2 && element.duplicateIDStatus == 1) && !element.emailValidationStatus == 1">
                <span title="ID and Email are Duplicate">ID and Entity Name are already Exists</span>
              </ng-container>

              <ng-container
                *ngIf="(element.emailValidationStatus == 1 && element.duplicateIDStatus == 1) && !element.duplicateEmailEntityStatus == 2">
                <span title="ID and Email are Duplicate">ID and Email are already Exists</span>
              </ng-container>

              <ng-container
                *ngIf="element.emailValidationStatus == 1 && element.duplicateEmailEntityStatus == 1 && element.duplicateIDStatus == 2">
                <span title="ID and Email are already Exists">ID, Email and Entity Name are already Exists</span>
              </ng-container>

              <ng-container
                *ngIf="element.emailValidationStatus == 2 && element.duplicateIDStatus == 1 && element.duplicateEmailEntityStatus == 2">
                <span title="ID, Email and Entity Name are Duplicate">ID, Email and Entity Name are Duplicate</span>
              </ng-container>
              <ng-container
                *ngIf="element.emailValidationStatus == 1 && element.duplicateIDStatus == 2 && element.duplicateEmailEntityStatus == 2">
                <span title="ID, Email and Entity Name are already Exists">ID, Email and Entity
                  Name are already Exists</span>
              </ng-container>

              <ng-container *ngIf="element.isIdValidLength">
                <span title="Max 100 Alphanumeric in ID Column">Max 100 Alphanumeric in ID
                  Column
                </span>
              </ng-container>

              <ng-container *ngIf="(element.duplicateIDStatus && !element.emailValidationStatus && !element.duplicateEmailEntityStatus) || element.isAlphanumericId">
                <span title="ID already Exists" *ngIf="element.duplicateIDStatus === 2">ID already Exists</span>
                <span title="Duplicate ID's in file" *ngIf="element.duplicateIDStatus === 1">Duplicate ID's in
                  file</span>
                <span title="ID: Alphanumeric" *ngIf="element.isAlphanumericId === 1">ID: Alphanumeric</span>
              </ng-container>

              <ng-container
                *ngIf="!element.duplicateIDStatus && !element.isAlphanumericId && !element.isIdValidLength && !element.duplicateEmailEntityStatus && element.InvalidEntityType == 0 && element.InvalidFirstName == 0 && element.InvalidLastName == 0 && element.InvalidEntityName == 0">
                <span title="{{ statusCheck[element.emailValidationStatus] }}">
                  {{ statusCheck[element.emailValidationStatus] }}
                </span>
              </ng-container>

              <ng-container
                *ngIf="element.InvalidEntityType == 1 && element.InvalidEntityName == 0 && element.InvalidFirstName == 0 && element.InvalidLastName == 0">
                <span title="Entity Type is Invalid">Entity Type is Invalid</span>
              </ng-container>

              <ng-container
                *ngIf="element.InvalidFirstName == 1 && element.InvalidLastName == 0 && element.InvalidEntityName == 0 && element.InvalidEntityType == 0">
                <span title="First/Last Name is Invalid">First/Last Name is Invalid</span>
              </ng-container>

              <ng-container
                *ngIf="element.InvalidLastName == 1 && element.InvalidFirstName == 0 && element.InvalidEntityName == 0 && element.InvalidEntityType == 0">
                <span title="First/Last Name is Invalid">First/Last Name is Invalid</span>
              </ng-container>

              <ng-container
                *ngIf="element.InvalidEntityName == 1 && element.InvalidEntityType == 0 && element.InvalidFirstName == 0 && element.InvalidLastName == 0">
                <span title="Entity Name is Invalid">Entity Name is Invalid</span>
              </ng-container>

              <ng-container
                *ngIf="element.InvalidFirstName == 1 && element.InvalidLastName == 1 && element.InvalidEntityName == 0 && element.InvalidEntityType == 0">
                <span title="First & Last Name is Invalid">First & Last Name is Invalid</span>
              </ng-container>

              <ng-container
                *ngIf="element.InvalidEntityName == 1 && element.InvalidEntityType == 1 && element.InvalidFirstName == 0 && element.InvalidLastName == 0">
                <span title="Entity Type & Entity Name is Invalid">Entity Type & Entity Name is Invalid</span>
              </ng-container>

              <ng-container
                *ngIf="element.InvalidLastName == 1 && element.InvalidEntityName == 1 && element.InvalidEntityType == 1 && element.InvalidFirstName == 0">
                <span title="First/Last Name,Entity Type & Entity Name is Invalid">First/Last Name,Entity Type & Entity
                  Name is Invalid</span>
              </ng-container>

              <ng-container
                *ngIf="element.InvalidLastName == 1 && element.InvalidEntityName == 0 && element.InvalidEntityType == 1 && element.InvalidFirstName == 0">
                <span title="First/Last Name & Entity Type is Invalid">First/Last Name & Entity Type is Invalid</span>
              </ng-container>

              <ng-container
                *ngIf="element.InvalidLastName == 1 && element.InvalidEntityName == 1 && element.InvalidEntityType == 0 && element.InvalidFirstName == 0">
                <span title="First/Last Name & Entity Name is Invalid">First/Last Name & Entity Name is Invalid</span>
              </ng-container>

              <ng-container
                *ngIf="element.InvalidFirstName == 1 && element.InvalidEntityName == 1 && element.InvalidEntityType == 1 && element.InvalidLastName == 1">
                <span title="First Name,Last Name,Entity Type & Entity Name is Invalid">First Name,Last Name,Entity Type &
                  Entity Name is Invalid</span>
              </ng-container>

            </td>
          </ng-container>

          <ng-container matColumnDef="actions" stickyEnd>
            <th mat-header-cell *matHeaderCellDef class="text-end">
              Actions
            </th>

            <td mat-cell *matCellDef="let element" class="text-end">
              <div class="button-group justify-content-end flex-nowrap gap-0">
                <ng-container *ngIf="element.isEditMode; else viewMode">
                  <button mat-button class="button-small-icon" title="done" (click)="onEditSave(element)">
                    <i class="fa-regular fa-check text-success"></i>
                  </button>
                  <button mat-button class="button-small-icon" title="Clear"
                    (click)="element.isEditMode=false; element.clientEmail = element.oldEmailAddress; element.id = element.oldid">
                    <i class="fa-regular fa-xmark text-error"></i>
                  </button>
                </ng-container>

                <ng-template #viewMode>
                  <button mat-button class="button-small-icon" title="Edit"
                    (click)="element.isEditMode=true; element.oldEmailAddress = element.clientEmail; element.oldid = element.id">
                    <i class="fa-regular fa-pen fs-15"></i>
                  </button>
                  <button mat-button class="button-small-icon" title="Delete"
                    (click)="onEditDelete(element)">
                    <i class="fa-regular fa-trash-can"></i>
                  </button>
                </ng-template>
              </div>
            </td>

          </ng-container>

          <tr mat-header-row *matHeaderRowDef="addClientColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: addClientColumns;"></tr>
        </table>
      </div>
    </ng-container>

    <!-- staff data -->
    <ng-container *ngIf="uploadSuccess && (role=='staff' || role=='partnerstaff')">
      <div class="importpreview-table">
        <div class="preview-header" *ngIf="processType == 'import'">
          <div class="preview-headerleft">

            <div class="header-text">Import Preview</div>
            <div class="team-members">Total Staff: {{staffList ? staffList.length : 0}}</div>
          </div>
          <div class="preview-headerright">
            <button mat-flat-button class="" color="primary" (click)="csvReupload.click()">
              <span class="btn-label">Re-import</span>
            </button>
            <input hidden type="file" #csvReupload name="photo" id="photo" (change)="uploadListener($event, 'manual')"
              accept=".csv" />
          </div>
        </div>
        <div class="upload-table" [ngClass]="{'addnew-section': processType == 'manual'}">
          <div class="tbl-grid-container tbl1-grid-impstaff">
            <table #formData class="customized-sort" mat-table [dataSource]="staffList" matSort
              (matSortChange)="sortData($event)" matSortActive="firstName" matSortDirection="asc" matSortDisableClear>
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> ID </th>
                <td mat-cell *matCellDef="let element" class="col-Id" title="{{element.id | uppercase}}"
                  [ngClass]="element.duplicateIDStatus === 1 || element.duplicateIDStatus === 2 || element.isAlphanumericId || element.isIdValidLength ? 'text-error' : ' '">
                  <ng-container *ngIf="element.isEditMode; else viewMode">
                    <input #idField maxlength="100" class="field-mat-input" [value]="element.id"
                      [ngClass]="element.duplicateIDStatus || element.isAlphanumericId  || element.isIdValidLength ? 'text-error' : ' '"
                      [(ngModel)]="element.id" (input)="checkCharLength($event.target.value)"
                      (keypress)="onlyAlphNumericKeys($event)">
                  </ng-container>
                  <ng-template #viewMode>
                    {{element.id | uppercase}}
                  </ng-template>
                </td>
              </ng-container>

              <ng-container matColumnDef="firstName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="firstName">Name</th>
                <td title="{{element.partnerFirstName + ' ' + element.partnerLastName}}" mat-cell
                  *matCellDef="let element" class="col-stf-name">
                  <p>{{element.partnerFirstName + ' ' + element.partnerLastName}}</p>
                </td>
              </ng-container>

              <ng-container matColumnDef="practiceUnit">
                <th mat-header-cell *matHeaderCellDef> Practice Unit </th>
                <td mat-cell *matCellDef="let element" class="col-prct-unit" title="{{element.practiceUnitName}}">
                  {{element.practiceUnitName}}
                </td>
              </ng-container>

              <ng-container matColumnDef="designationName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="designationName" class="col-stf-desg">
                  Designation
                </th>
                <td title="{{element.designation}}" mat-cell *matCellDef="let element" class="col-stf-desg">
                  {{element.designation}}
                </td>
              </ng-container>
              <ng-container matColumnDef="emailAddress">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="emailAddress" class="col-cli-email"> Email </th>
                <td mat-cell *matCellDef="let element" class="col-email" title="{{element.partnerEmail}}"
                  [ngClass]="element.emailValidationStatus ? 'text-error' : ' '">
                  <ng-container *ngIf="element.isEditMode; else viewMode">
                    <input class="field-mat-input" [(ngModel)]="element.partnerEmail" [value]="element.partnerEmail"
                      [ngClass]="element.emailValidationStatus ? 'text-error' : ' '"
                      (input)="onlyLowercase($event, element, 'staff')">
                  </ng-container>
                  <ng-template #viewMode>
                    {{element.partnerEmail}}
                  </ng-template>
                </td>
              </ng-container>

              <ng-container matColumnDef="license">
                <th mat-header-cell *matHeaderCellDef> License # </th>
                <td title="{{element.licenseNo}}" mat-cell *matCellDef="let element" class="col-stf-lic">
                  {{element.licenseNo}}
                </td>
              </ng-container>

              <ng-container matColumnDef="officeLocation">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="officeLocation">
                  Office Location
                </th>
                <td title="{{element.officeLocation}}" mat-cell *matCellDef="let element" class="col-stf-loc">
                  {{element.officeLocation}}
                </td>
              </ng-container>

              <ng-container matColumnDef="businessNo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="businessNo">Business Number </th>
                <td title="{{element.partnerBusinessPhone}}" mat-cell *matCellDef="let element" class="col-stf-busnsno">
                  {{element.partnerBusinessPhone}}
                </td>
              </ng-container>

              <ng-container matColumnDef="phoneNo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="phoneNo">Phone Number</th>
                <td title="{{element.PartnerCellNo}}" mat-cell *matCellDef="let element" class="col-stf-call">
                  {{element.PartnerCellNo}}
                </td>
              </ng-container>
              <ng-container matColumnDef="emailValidationStatus">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element" [ngClass]="(element.emailValidationStatus || element.duplicateIDStatus || element.isAlphanumericId || element.isIdValidLength) ? 'text-error' : ' '"
                  class="col-stf-call">
                  <ng-container *ngIf="element.emailValidationStatus == 1  && element.duplicateIDStatus == 2">
                    <span title="ID and Email are already Exists">ID and Email are already Exists
                    </span>
                  </ng-container>
                  <ng-container *ngIf="element.emailValidationStatus == 2 && element.duplicateIDStatus == 1">
                    <span title="ID and Email are Duplicate">ID and Email are Duplicate</span>
                  </ng-container>

                  <ng-container *ngIf="element.isIdValidLength">
                    <span title="Max 100 Alphanumeric in ID Column">Max 100 Alphanumeric in ID
                      Column
                    </span>
                  </ng-container>

                  <ng-container
                    *ngIf="(element.duplicateIDStatus && !element.emailValidationStatus) || element.isAlphanumericId">
                      <span title="ID already Exists" *ngIf="element.duplicateIDStatus === 2">ID already Exists</span>
                      <span title="Duplicate ID's in file" *ngIf="element.duplicateIDStatus === 1">Duplicate ID's in
                        file</span>
                      <span title="ID: Alphanumeric" *ngIf="element.isAlphanumericId === 1">ID: Alphanumeric</span>
                  </ng-container>

                  <ng-container
                    *ngIf="!element.duplicateIDStatus && !element.isAlphanumericId && !element.isIdValidLength">
                    <p title="{{ statusCheck[element.emailValidationStatus] }}">
                      {{ statusCheck[element.emailValidationStatus] }}
                    </p>
                  </ng-container>

                </td>
              </ng-container>

              <ng-container matColumnDef="actions" stickyEnd>
                <th mat-header-cell *matHeaderCellDef class="col-action">
                  Actions
                </th>
                <td mat-cell *matCellDef="let element" class="col-action">
                  <div class="button-group justify-content-end flex-nowrap gap-0">
                    <ng-container *ngIf="element.isEditMode; else viewMode">
                      <button mat-button class="button-small-icon" title="done" class="transparent-btn" (click)="onEditSave(element)">
                        <i class="fa-regular fa-check text-success"></i>
                      </button>
                      <button mat-button class="button-small-icon" title="Clear" (click)="element.isEditMode=false; element.partnerEmail = element.oldEmailAddress; element.id = element.oldid">
                        <i class="fa-regular fa-xmark text-error"></i>
                      </button>
                    </ng-container>

                    <ng-template #viewMode>
                      <button mat-button class="button-small-icon" title="Edit" (click)="element.isEditMode=true;
                             element.oldEmailAddress = element.partnerEmail;
                             element.oldid = element.id">
                                      <i class="fa-regular fa-pen fs-15"></i>
                      </button>
                      <button mat-button class="button-small-icon" title="Delete" (click)="onEditDelete(element)">
                        <i class="fa-regular fa-trash-can"></i>
                      </button>
                    </ng-template>
                  </div>
                </td>

              </ng-container>
              <tr mat-header-row *matHeaderRowDef="partnersStaffColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: partnersStaffColumns;"></tr>
            </table>
          </div>
        </div>
        <div>
          <button mat-raised-button class="save-btn float-right" [disabled]="onValidationClick()" color="primary"
            [ngClass]="{'disable-button': onValidationClick()}" (click)="saveData()">
            Save
          </button>
        </div>
      </div>
    </ng-container>

    <!--HOW TO EDIT CSV-->
    <!--partner-client-->
    <ng-template #howtoEdit *ngIf="role == 'partnerclient'">
      <div class="countable-dialog" style="width:1200px;">
        <div mat-dialog-title class="dialog-header">
          <h4 class="title">Steps for how to create a template (CLIENT)</h4>
          <button mat-button class="close" [mat-dialog-close]="false">
            <i class="fa-regular fa-xmark clear-icon"></i>
          </button>
        </div>

        <div mat-dialog-content class="dialog-body-content">
          <p class="d-flex flex-row align-items-center gap-8">1. Click on
            <button mat-button mat-flat-button class="button-small" color="primary">
                <span class="flexbox-row gap-8">
                    <i class="fa-regular fa-arrow-down-to-bracket text-white"></i>
                    <span class="button-label">Download Template</span>
                </span>
          </button> button
          </p>
          <p>2. A csv file will be downloaded</p>
          <p>3. Navigate to local location and open the csv file in Ms-excel</p>
          <p>4. Below are the column header names available:</p>
          <ol class="list-style-lower-alpha d-flex flex-column gap-8 ps-4">
            <li><span class="fw-semibold">Client Id:</span>Enter your Client Id (Example: 3030).</li>
            <li><span class="fw-semibold">Entity Name<sup class="text-error">*</sup> :</span>Enter your Entity Name
              (Example: Holmes lnc).</li>
            <li><span class="fw-semibold">Entity Type<sup class="text-error">*</sup> :</span>Enter your Entity Type
              (Example: Corporation).</li>
            <li>
              <p><span class="fw-semibold">Entity Type<sup class="text-error">*</sup> :</span>Enter your Entity Type
                (Example: Corporation).</p>
              <ol class="list-style-lower-roman d-flex flex-column gap-8 ps-4 mt-2">
                <li>Corporation</li>
                <li>Partnership</li>
                <li>Sole Proprietor</li>
                <li>Trust</li>
              </ol>
            </li>
            <li><span class="fw-semibold">Incorporation Date:</span>Enter your Incorporation Date (Example:
              Mar-23-2016).</li>
            <li><span class="fw-semibold">First Name<sup class="text-error">*</sup>:</span>Enter your First Name
              (Example: Robert).</li>
            <li><span class="fw-semibold">Last Name<sup class="text-error">*</sup>:</span>Enter your Last Name (Example:
              Downey).</li>
            <li><span class="fw-semibold">Email<sup class="text-error">*</sup>:</span>Enter your Email (Example:
                xyz+12/ag3&#64;gmail.com).
            </li>
            <li><span class="fw-semibold">Business Phone:</span>Enter your Business Phone (Example: 1234563030).</li>
            <li><span class="fw-semibold">Cell Phone:</span>Enter your Cell Phone (Example: 1234563030).</li>
            <li><span class="fw-semibold">Jurisdiction of Entity:</span>Enter your Jurisdiction Of Entity (Example:
              Ontario).</li>
            <li><span class="fw-semibold">Address:</span>Enter your Address (Example: 111/333 Amphoe).</li>
            <li><span class="fw-semibold">City:</span>Enter your City (Example: Chanthapuri).</li>
            <li><span class="fw-semibold">State/Province:</span>Enter your State/Province (Example: Chanthapuri).</li>
            <li><span class="fw-semibold">Country:</span>Enter your Country (Example: Thailand).</li>
            <li><span class="fw-semibold">Postal Code:</span>Enter your Postal Code (Example: 22000).</li>
            <li><span class="fw-semibold">Engagement Partner<sup class="text-error">*</sup>:</span>Enter your Engagement
              Partner (Example: John Paul).</li>
            <li><span class="fw-semibold">Engagement Partner Email:</span>Enter your Engagement Partner Email (Example:
                xyz+12/ag3&#64;gmail.com).
            </li>
            <li><span class="fw-semibold">Group Name:</span>Enter your Group Name (Example: Holmes group).</li>
            <li><span class="fw-semibold">Group Name:</span>Enter your Group Name (Example: Holmes group).</li>
          </ol>
          <p class="fw-semibold"><span class="texy-error">*</span> included columns are mandatory.</p>
          <img src="/assets/images/firm-client12.PNG" alt="firm-cli-table" />
          <img src="/assets/images/firm-client11.PNG" alt="firm-cli-table" />
          <p>5. Add your rows under header and save </p>
          <img src="/assets/images/firm-client22.PNG" alt="firm-cli-table" />
          <img src="/assets/images/firm-client33.PNG" alt="firm-cli-table" />
          <p class="d-flex flex-row align-items-center gap-4">By clicking on save button, <img
              src="../../../../assets/images/save-icon.svg" alt="save" width="16" /> File will be saved</p>
          <p>6. Navigate to the location where the file is saved</p>
          <p>7. Drag and drop the file in ‘export icon’ field</p>
          <div class="file-upload">
            <div class="upload-icon">
                <i class="fa-regular fa-cloud-arrow-up"></i>
            </div>
            <div class="file-wrapper">
              <input type="file" #csvReader name="photo" id="photo" multiple
                [ngClass]="{'disabled-browse-link':  (firm?.isPaused || billing?.isPaused) || (firm?.isDeleted || firm?.isDeleteInProgress) }"
                (change)="dropFiles($event.target.files, folder)" #fileUploader accept=".csv" />
              <p class="text-link text-primary fw-medium m-0">Click to upload <span class="text-gray-6 fw-regular">or
                  drag
                  and drop</span></p>
            </div>
          </div>
          <p>8. After importing the file, document will be in table format</p>
        </div>

        <div mat-dialog-actions class="dialog-footer button-group">
          <button mat-button mat-stroked-button color="dark" class="button-medium"
            [mat-dialog-close]="true">Close</button>
          <button mat-button mat-flat-button color="primary" class="button-medium" (click)="downloadPDF()"
            [mat-dialog-close]>
            <span class="flexbox-row gap-8">
                <i class="fa-regular fa-arrow-down-to-bracket text-white"></i>
                <span class="button-label">Download as PDF</span>
            </span>
          </button>
        </div>
      </div>
    </ng-template>

    <!--partner-staff-->
    <ng-template #howtoEdit *ngIf="role == 'partnerstaff'">
      <div class="edit-csv">
        <div class="flex-box-left-right csv-head create-folder-dialogbox-header">
          <h4 class="create-folder">STEPS FOR HOW TO EDIT CSV (STAFF)</h4>
          <!--<button type="button" class="transparent-btn close-btn" [mat-dialog-close]>
        <i class="fa-regular fa-xmark clear-icon"></i>
      </button>-->
        </div>
        <div class="main-step">
          <p>1. Click on ‘Download CSV Template’ button</p>
          <div class=" download-csv">
            <i class="fa-regular fa-arrow-down-to-line download-csv-icn"></i>
            <div class="dnload-label">
              <span class="btn-label">
                Download CSV Template
              </span>
            </div>
          </div>
          <p>2. A csv file will be downloaded</p>
          <p>3. Navigate to local location and open the csv file in Ms-excel</p>
          <p>4. Below are the column header names available:</p>
          <p class="main-step-sub-txt main-step-txt">a. <span class="bold-txt">Role:</span> Enter your Role (Example:
            partner).</p>
          <p class="main-step-sub-txt main-step-txt">b. <span class="bold-txt">Designation:</span> Enter your
            Designation
            (Example: staff).</p>
          <p class="main-step-sub-txt main-step-txt">c. <span class="bold-txt">Practice Unit:</span> Enter your Practice
            Unit (Example: People tech).</p>
          <p class="main-step-sub-txt main-step-txt">d. <span class="bold-txt">ID:</span> Enter your ID (Example: 1456)
          </p>
          <p class="main-step-sub-txt main-step-txt">e. <span class="bold-txt">First Name:</span> Enter your First Name
            (Example: Ravi).</p>
          <p class="main-step-sub-txt main-step-txt">f. <span class="bold-txt">Last Name:</span> Enter your Last Name
            (Example: Kumar).</p>
          <p class="main-step-sub-txt main-step-txt">g. <span class="bold-txt">Email:</span> Enter your Email address
              (Example: xyz+12/ag3&#64;gmail.com).</p>
          <p class="main-step-sub-txt main-step-txt">h. <span class="bold-txt">Licence Number:</span> Enter your Licence
            Number (Example: 12345).</p>
          <p class="main-step-sub-txt main-step-txt">i. <span class="bold-txt">Location:</span> Enter your Location
            (Example: Bangalore).</p>
          <p class="main-step-sub-txt main-step-txt">j. <span class="bold-txt">Business Number:</span> Enter your
            Business
            Number (Example:040-123456).</p>
          <p class="main-step-sub-txt main-step-txt">k. <span class="bold-txt">Cell Number:</span> Enter your Cell
            Number
            (Example:915612350).</p>
          <div class="table-img">
            <img class="table-img-grid" src="../../../../assets/images/partner-staff1.svg" alt="partner-staff-table" />
          </div>
          <p>5. Add your rows under header and save </p>
          <div class="table-img">
            <img class="table-img-grid" src="../../../../assets/images/partner-staff.svg" alt="partner-staff-table" />
            <div class="main-step-sub-txt save-excel">
              <p class=" main-step-txt"></p>
              By clicking on save button,
              <div class="save-icn">
                <img class="save-icn-excel" src="../../../../assets/images/save-icon.svg" alt="save" />
              </div>
              <p> File will be saved</p>
            </div>
          </div>
          <p>6. Navigate to the location where the file is saved</p>
          <p>7. Drag and drop the file in ‘export icon’ field</p>
          <p>8. After importing the file, document will be in table format</p>
        </div>
        <div mat-dialog-actions>
          <div class="csv-actions">
            <div class="dl-pdf">
              <button mat-flat-button class="dl-btn" (click)="downloadPDF()" color="primary" [mat-dialog-close]>
                <i class="fa-regular fa-arrow-down-to-line dl-pdf-icon"></i>
                <span class="btn-labl">
                  Download as PDF
                </span>
              </button>
            </div>
            <div class="ok-click">
              <button mat-flat-button class="ok-btn " color="primary" [mat-dialog-close]>Close</button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <!--firm-partner/staff-->
    <ng-template #howtoEdit *ngIf="(role == 'partner' || role == 'staff')">
      <div class="edit-csv">
        <div class="flex-box-left-right csv-head create-folder-dialogbox-header">
          <h4 class="create-folder">STEPS FOR HOW TO EDIT CSV (PARTNER/STAFF)</h4>
          <!--<button type="button" class="transparent-btn close-btn" [mat-dialog-close]>
        <i class="fa-regular fa-xmark clear-icon"></i>
      </button>-->
        </div>
        <div class="main-step">
          <p>1. Click on ‘Download CSV Template’ button</p>
          <div class=" download-csv">
            <i class="fa-regular fa-arrow-down-to-line download-csv-icn"></i>
            <div class="dnload-label">
              <span class="btn-label">
                Download CSV Template
              </span>
            </div>
          </div>
          <p>2. A csv file will be downloaded</p>
          <p>3. Navigate to local location and open the csv file in Ms-excel</p>
          <p>4. Below are the column header names available:</p>
          <p class="main-step-sub-txt main-step-txt">a. <span class="bold-txt">Role:</span> Enter your Role (Example:
            Staff).</p>
          <p class="main-step-sub-txt main-step-txt">b. <span class="bold-txt">Member ID:</span> Enter your Member ID
            (Example: 1234).</p>
          <p class="main-step-sub-txt main-step-txt">c. <span class="bold-txt">First Name:</span> Enter your First Name
            (Example: Ravi).</p>
          <p class="main-step-sub-txt main-step-txt">d. <span class="bold-txt">Last Name:</span> Enter your Last Name
            (Example: Kumar).</p>
          <p class="main-step-sub-txt main-step-txt">e. <span class="bold-txt">Email:</span> Enter your Email address
              (Example: xyz+12/ag3&#64;gmail.com).</p>

          <p class="main-step-sub-txt main-step-txt">f. <span class="bold-txt">Business Number:</span> Enter your
            Business
            Number (Example:040-123456).</p>
          <p class="main-step-sub-txt main-step-txt">g. <span class="bold-txt">Cell Number:</span> Enter your Cell
            Number
            (Example:915612350).</p>
          <p class="main-step-sub-txt main-step-txt">h. <span class="bold-txt">Address:</span> Enter your Address
            (Example:
            122/c, front avenue).</p>
          <p class="main-step-sub-txt main-step-txt">i. <span class="bold-txt">City:</span> Enter your city (Example:
            calgary).</p>
          <p class="main-step-sub-txt main-step-txt">j. <span class="bold-txt">Province:</span> Enter your province
            (Example: Alberta).</p>
          <p class="main-step-sub-txt main-step-txt">k. <span class="bold-txt">Country:</span> Enter your country
            (Example:
            canada).</p>
          <p class="main-step-sub-txt main-step-txt">l. <span class="bold-txt">Postal Code:</span> Enter your postal
            code
            (Example: MV1234).</p>
          <p class="main-step-sub-txt main-step-txt">m. <span class="bold-txt">License Number:</span> Enter your License
            Number (Example: 12345).</p>
          <p class="main-step-sub-txt main-step-txt">n. <span class="bold-txt">Hourly Rate:</span> Enter your Hourly
            rate
            (Example: Dec-31-2016).</p>

          <div class="table-img">
            <img class="table-img-grid" src="../../../../assets/images/firm-partner1.svg" alt="firm-staff-table" />
          </div>
          <p>5. Add your rows under header and save </p>
          <div class="table-img">
            <img class="table-img-grid" src="../../../../assets/images/firm-partner.svg" alt="firm-staff-table" />
            <div class="main-step-sub-txt save-excel">
              <p class=" main-step-txt"></p>
              By clicking on save button,
              <div class="save-icn">
                <img class="save-icn-excel" src="../../../../assets/images/save-icon.svg" alt="save" />
              </div>
              <p> File will be saved</p>
            </div>
          </div>
          <p>6. Navigate to the location where the file is saved</p>
          <p>7. Drag and drop the file in ‘export icon’ field</p>
          <p>8. After importing the file, document will be in table format</p>
        </div>
        <div mat-dialog-actions>
          <div class="csv-actions">
            <div class="dl-pdf">
              <button mat-flat-button class="dl-btn" (click)="downloadPDF()" color="primary" [mat-dialog-close]>
                <i class="fa-regular fa-arrow-down-to-line dl-pdf-icon"></i>
                <span class="btn-labl">
                  Download as PDF
                </span>
              </button>
            </div>
            <div class="ok-click">
              <button mat-flat-button class="ok-btn " color="primary" [mat-dialog-close]>Close</button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <!--firm-client-->
    <ng-template #howtoEdit *ngIf="role == 'client'">
      <div class="edit-csv">
        <div class="flex-box-left-right csv-head create-folder-dialogbox-header">
          <h4 class="create-folder">STEPS FOR HOW TO EDIT CSV (CLIENT)</h4>
          <!--<button type="button" class="transparent-btn close-btn" [mat-dialog-close]>
        <i class="fa-regular fa-xmark clear-icon"></i>
      </button>-->
        </div>
        <div class="main-step">
          <p>1. Click on ‘Download CSV Template’ button</p>
          <div class=" download-csv">
            <i class="fa-regular fa-arrow-down-to-line download-csv-icn"></i>
            <div class="dnload-label">
              <span class="btn-label">
                Download CSV Template
              </span>
            </div>
          </div>
          <p>2. A csv file will be downloaded</p>
          <p>3. Navigate to local location and open the csv file in Ms-excel</p>
          <p>4. Below are the column header names available:</p>
          <p class="main-step-sub-txt main-step-txt">a. <span class="bold-txt">Legal Entity Name:</span>Enter your Legal
            Entity Name (Example: Client AV).</p>
          <p class="main-step-sub-txt main-step-txt">b. <span class="bold-txt">Contact Person:</span> Enter your Contact
            Person (Example: Ravi, Kumar).</p>
          <p class="main-step-sub-txt main-step-txt">c. <span class="bold-txt">Email:</span> Enter your Email address
              (Example: test&#64;gmail.com).</p>
          <p class="main-step-sub-txt main-step-txt">d. <span class="bold-txt">ID:</span> Enter your ID (Example: 1456)
          </p>
          <p class="main-step-sub-txt main-step-txt">e. <span class="bold-txt">Business Number:</span> Enter your
            Business
            Number (Example:040-123456).</p>
          <p class="main-step-sub-txt main-step-txt">f. <span class="bold-txt">Cell Number:</span> Enter your Cell
            Number
            (Example:915612350).</p>
          <p class="main-step-sub-txt main-step-txt">g. <span class="bold-txt">Address:</span> Enter your address
            (Example:
            122/c, front avenue).</p>
          <p class="main-step-sub-txt main-step-txt">h. <span class="bold-txt">City:</span> Enter your city (Example:
            calgary).</p>
          <p class="main-step-sub-txt main-step-txt">i. <span class="bold-txt">Province:</span> Enter your province
            (Example: Alberta).</p>
          <p class="main-step-sub-txt main-step-txt">j. <span class="bold-txt">Country:</span> Enter your country
            (Example:
            canada).</p>
          <p class="main-step-sub-txt main-step-txt">k. <span class="bold-txt">Postal Code:</span> Enter your postal
            code
            (Example: MV1234).</p>
          <p class="main-step-sub-txt main-step-txt">l. <span class="bold-txt">Entity Type:</span> Mentioned ‘Entity
            Type’
            in the below:</p>
          <p class="main-step-txt main-step-third-sub">i. Corporation</p>
          <p class="main-step-txt main-step-third-sub">ii. Partnership</p>
          <p class="main-step-txt main-step-third-sub">iii. Sole Proprietor</p>
          <p class="main-step-txt main-step-third-sub">iv. Trust </p>
          <p class="main-step-sub-txt main-step-txt">m. <span class="bold-txt">Incorporation Date:</span> Enter your
            Incorporation date in the format of “MM/DD/YYYY” (Example:12/20/2019).</p>
          <p class="main-step-sub-txt main-step-txt">n. <span class="bold-txt">Client Group Name:</span> Enter your
            client
            group name (Example:Purple Groups).</p>
          <p class="main-step-sub-txt main-step-txt">o. <span class="bold-txt">Engagement Partner:</span> Enter your
            engagement partner (Example:John Paul).</p>
          <p class="main-step-sub-txt main-step-txt">p. <span class="bold-txt">Engagement Partner Email:</span> Enter
            your
              engagement partner email(Example:test&#64;gmail.com).</p>
          <div class="table-img">
            <img class="table-img-grid" src="../../../../assets/images/firm_client1.svg" alt="firm-cli-table" />
          </div>
          <p>5. Add your rows under header and save </p>
          <div class="table-img">
            <img class="table-img-grid" src="../../../../assets/images/firm_client.svg" alt="firm-cli-table" />
            <div class="main-step-sub-txt save-excel">
              <p class=" main-step-txt"></p>
              By clicking on save button,
              <div class="save-icn">
                <img class="save-icn-excel" src="../../../../assets/images/save-icon.svg" alt="save" />
              </div>
              <p> File will be saved</p>
            </div>
          </div>
          <p>6. Navigate to the location where the file is saved</p>
          <p>7. Drag and drop the file in ‘export icon’ field</p>
          <p>8. After importing the file, document will be in table format</p>
        </div>
        <div mat-dialog-actions>
          <div class="csv-actions">
            <div class="dl-pdf">
              <button mat-flat-button class="dl-btn" (click)="downloadPDF()" color="primary" [mat-dialog-close]>
                <i class="fa-regular fa-arrow-down-to-line dl-pdf-icon"></i>
                <span class="btn-labl">
                  Download as PDF
                </span>
              </button>
            </div>
            <div class="ok-click">
              <button mat-flat-button class="ok-btn " color="primary" [mat-dialog-close]>Close</button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #importCsvDialog>
  <h4 mat-dialog-title class="flex-box-left">
    <i class="fa-regular fa-circle-xmark text-warning-darker"></i>
    <span>Licence column not valid in Sheet</span>
  </h4>
  <div mat-dialog-content>
    <div class="flex-box-left">
        <i class="fa-regular fa-tag"></i>
      <p>Please enter Only Numeric and Max 11 Numeric in Licence Column</p>
    </div>
    <div class="flex-box-left">
        <i class="fa-regular fa-tag"></i>
      <p>Please enter Only Unique Licence Number in Licence Column</p>
    </div>

  </div>
  <div mat-dialog-actions>
    <div class="flex-box full-row">
      <button mat-flat-button color="primary" [mat-dialog-close] cdkFocusInitial>Ok</button>
    </div>
  </div>
</ng-template>
